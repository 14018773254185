import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-institute-info',
  templateUrl: './site-institute-info.component.html',
  styleUrls: ['./site-institute-info.component.scss']
})
export class SiteInstituteInfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SiteInstituteInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _router: Router
  ) { }

  imgUrl='';
  ngOnInit(): void {
    // console.log(this.data);
    this.imgUrl = this.data.addimageUrl;
    let url = this.imgUrl;
    let pattern = /^((http|https|ftp):\/\/)/;
    if(pattern.test(url)){
      this.imgUrl = url;
    }
    else{
      this.imgUrl = "";
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
