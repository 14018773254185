import { environment } from './../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupDbService } from '../../authentication/services/popup-db.service';
@Injectable({
  providedIn: 'root',
})
export class EnquiryService {
  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private _dbStorage: PopupDbService
  ) {}
  

  private pageNoSnapshot: number = 0;
  private sizeSnapshot: number = 10;
  private isInsideEdit: boolean = false;

  getPageNo() {
    return this.pageNoSnapshot;
  }
  setPageNo(pageNo) {
    this.pageNoSnapshot = pageNo;
  }

  getSizeSanpshot() {
    return this.sizeSnapshot;
  }
  setSizeSnapshot(size) {
    this.sizeSnapshot = size;
  }

  getIsInsideEdit() {
    return this.isInsideEdit;
  }
  setIsInsideEdit(value) {
    this.isInsideEdit = value;
  }

  
  openDialog(componentName, height, width, dataModel) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
    });
  }
  openMessageDialog(componentName, height, width, dataModel, maxWidth) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      maxWidth: maxWidth,
      disableClose: true,
    });
  }
  MessageDialog(componentName, height, width, dataModel, maxWidth, maxHeight) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      disableClose: true,
    });
  }

  suggestDialog(componentName, panelClass, height, width, dataModel, maxwidth) {
    return this.dialog.open(componentName, {
      panelClass: panelClass,
      width: width,
      height: height,
      data: {
        dataModel: dataModel,
      },
      disableClose: true,
      maxWidth: maxwidth,
    });
  }

  filterDialog(componentName, panelClass, height, width, position, data) {
    return this.dialog.open(componentName, {
      panelClass: panelClass,
      width: width,
      height: height,
      position: position,
      data: data,
      disableClose: true,
    });
  }

  editprofileDialog(componentName, height, width) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      disableClose: true,
    });
  }
  editprofile(componentName, height, width, data) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: data,
      disableClose: true,
    });
  }

  openSnack(message, action) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  createEnquiry(data) {
    return this.http.post(
      environment.baseUrl + `/enquiry/enquiry/tenant/`,
      data
    );
  }

  getEnquiryList(isMyLead, isOldLead, pageNo, size, staffId) {
    // const token = this._dbStorage.getToken();
    // var headers_object = new HttpHeaders().set(
    //   'Authorization',
    //   'Bearer ' + token
    // );
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/?isMyLeads=${isMyLead}&isOldLeads=${isOldLead}&pageNo=${pageNo}&size=${size}&staffId=${staffId}`
    );
  }
  //pagination

  getEnquiryListForPagination(isMyLead, isOldLead, pageNo, size, staffId) {
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/ist/v1/paged?isMyLeads=${isMyLead}&isOldLeads=${isOldLead}&pageNo=${pageNo}&size=${size}&staffId=${staffId}`
    );
  }
  allEnquiryList(pageNo, size) {
    // const token = this._dbStorage.getToken();
    // var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/?isOldLeads=false&pageNo=${pageNo}&size=${size}`
    );
  }
  //pagination

  allEnquiryListForPagination(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/ist/v1/paged?isOldLeads=false&pageNo=${pageNo}&size=${size}`
    );
  }

  callLog(data) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/${data.leadId}/calllog`,
      data
    );
  }

  assignStaff(leadId) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/${leadId.leadId}/staff`,
      leadId
    );
  }

  updateStatus(leadId) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/${leadId.leadId}/status`,
      leadId
    );
  }

  search(data) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/search`,
      data
    );
  }

  getStaff() {
    return this.http.get(environment.baseUrl + `/user/staff/ist/all`);
  }
  updateStaffProfile(data, staffId) {
    return this.http.put(
      environment.baseUrl +
        `/user/staff/${staffId}/update/basic?contact=true&emergencyContact=false&kyc=false`,
      data
    );
  }

  sendMessage(leadId) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/${leadId.leadId}/email`,
      leadId
    );
  }

  deleteEnquiry(leadId) {
    return this.http.delete(
      environment.baseUrl + `/enquiry/enquiry/tenant/${leadId}`
    );
  }

  editEnquiry(data) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/`,
      data
    );
  }

  getPropertyForEnquiry(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/ist/properties?pageNo=${pageNo}&size=${size}`
    );
  }
  getPropertyForFavourite(leadId) {
    return this.http.get(
      environment.baseUrl + `/enquiry/enquiry/tenant/favorites/${leadId}`
    );
  }
  searchForAllEnquiry(data, pageNo, size) {
    return this.http.put(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/ist/search/?name=${data}&pageNo=${pageNo}&size=${size}`,
      data
    );
  }

  searchForMyEnquiry(data, staffId, pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/ist/search-my-enquiry/${staffId}?name=${data}&pageNo=${pageNo}&size=${size}`
    );
  }

  searchByNameProperties(data,pageNo,size) {
    return this.http.get(
      environment.baseUrl + `/enquiry/enquiry/tenant/ist/search-suggest?name=${data}&pageNo=${pageNo}&size=${size}`
    );
  }

  getcity() {
    return this.http.get(environment.baseUrl + `/property/master/ist/all`);
  }
  getLocalities(city) {
    return this.http.get(
      environment.baseUrl + `/property/master/ist/locality?city=${city}`
    );
  }
  getClusterLocality(city) {
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/cluster?city=${city.toLowerCase()}`
    );
  }
  getCityProperty(city) {
    return this.http.get(
      environment.baseUrl +
        `/property/master/ist/locality?city=${city.toLowerCase()}`
    );
  }
  suggestProperty(data) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/suggest/${data.leadId}`,
      data
    );
  }
  generatePaymentLink(bookingData) {
    return this.http.post(
      environment.baseUrl + `/finance/Booking/createBooking`,
      bookingData
    );
  }
  generateCashfreePaymentLink(paymentLink) {
    return this.http.post(
      environment.baseUrl + `/finance/Booking/cashfreepaymentlink`,
      paymentLink
    );
  }
  sendpaymentLink(bookingId) {
    return this.http.get(
      environment.baseUrl + `/finance/Booking/sendpaymentlink/${bookingId}`
    );
  }

  applyFilter(data, pageNo, size) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/ist/suggest/filters?pageNo=${pageNo}&size=${size}`,
      data
    );
  }

  getEnquiryOnFilters(path, data, staffId, pageNo, size) {
    if (path === 'allenquiry') {
      return this.http.put(
        environment.baseUrl +
          `/enquiry/enquiry/tenant/ist/all-enquiry/filter?pageNo=${pageNo}&size=${size}`,
        data
      );
    } else {
      return this.http.put(
        environment.baseUrl +
          `/enquiry/enquiry/tenant/ist/my-enquiry/filter/${staffId}?pageNo=${pageNo}&size=${size}`,
        data
      );
    }
  }
  fileUpload(data) {
    return this.http.post(
      environment.baseUrl + `/enquiry/enquiry/tenant/upload`,
      data
    );
  }
  createVisit(data, leadId) {
    return this.http.post(
      environment.baseUrl + `/enquiry/enquiry/tenant/${leadId}/visit`,
      data
    );
  }
  salesAudit(data, leadId) {
    return this.http.post(
      environment.baseUrl + `/enquiry/enquiry/tenant/audit/${leadId}`,
      data
    );
  }
  enquiryDashboard() {
    return this.http.get(
      // environment.baseUrl + `/enquiry/enquiry/tenant/ist/dashboard`
      environment.baseUrl + `/enquiry/enquiry/tenant/dashboard`
    );
  }
  filterDashboard(endDate, startDate) {
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/defaultdashboard?endDate=${endDate}&startDate=${startDate}`
    );
  }
  getstaffbyId(staffId) {
    return this.http.get(
      environment.baseUrl + `/user/staff/ist?allDetails=true&staffId=${staffId}`
    );
  }

  enquirysearch(data) {
    return this.http.get(
      environment.baseUrl + `/enquiry/enquiry/tenant/ist/search/?name=${data}`
    );
  }

  getallCity() {
    return this.http.get(environment.baseUrl + `/property/master/ist/all`);
  }

  getalllocalityBycity(data) {
    return this.http.put(
      environment.baseUrl + `/property/master/ist/citylist/localities`,
      data
    );
  }

  exportenquiryexcel(data) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/download/enquiries.xlsx`,
      data,
      { observe: 'response', responseType: 'blob' }
    );
  }
  searchStaff(name) {
    return this.http.get(
      environment.baseUrl + `/user/staff/ist/search?name=${name}`
    );
  }
  getenquiryById(id) {
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/ist/v1/get/one?displayId=${id}`
    );
  }
  getEnquiryDetailsById(leadId) {
    return this.http.get(
      environment.baseUrl + `/enquiry/enquiry/tenant/ist/v1/${leadId}`
    );
  }

  bulkEnquiryDelete(leadIds) {
    return this.http.put(
      environment.baseUrl + '/enquiry/enquiry/tenant/ist/bulk-delete',
      {
        id: leadIds,
      }
    );
  }
}
