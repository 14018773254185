<section class="main" >
    <!-- <div class="heading">
        <div class="pop-heading">Profile</div>
        <img class="close-btn" src="./../../../../../../../assets/images/common/close.svg"
            alt="close" />
    </div> -->
    <div *ngIf="staffDetails != undefined; else loader">
        <div class="view-profile">
            <div class="profile-details">
              <div class="profile-image">
                <div *ngIf="!profileImg; else profPic">
                  <div class="name-tag profile" (click)="openProfile()">
                    <div class="only-letter">{{nameletter | uppercase}}</div>
                  </div>
                </div>
                <ng-template #profPic>
                  <img
                  [src]="profileImg"
                  alt=""
                  width="200"
                  height="150"
                  class="profile-image"
                  />
                </ng-template>
                <img src='./../../../../../../../assets/images/enquiry/editenquiry.svg' class="edit-profile" (click)="editProfile()">
              </div>
              <!-- <ng-template #noImg>
                <div class="profile-image">
                  <div class="name-tag profile" (click)="openProfile()">
                    <div class="name">{{nameletter | uppercase}}</div>
                  </div>
                  <img src='./../../../../../../../assets/images/enquiry/editenquiry.svg' class="edit-profile" (click)="editProfile()">
                </div>
              </ng-template> -->
              <div>
              <div class="name">
                {{staffDetails.contactDetails.name | titlecase}}
              </div>
              <div class="emp-id">
                Emp ID :{{staffDetails.displayId}}
              </div>
            </div>
              <!-- <div class="details">
                <div class="name">
                  {{staffDetails.contactDetails.name | titlecase}}
                </div> -->
                <!-- <div class="phone"> -->
                  <!-- <img
                    src="./../../../../../assets/images/enquiry/phone.svg"
                    alt="phone"
                  /> -->
                  <!-- <i class="fa fa-phone" aria-hidden="true"></i>
                  <div class="number">
                    {{staffDetails.contactDetails.mobile.mobile}}
                  </div>
                </div> -->
                <!-- <div class="phone"> -->
                  <!-- <img
                    src="./../../../../../assets/images/profile/mail.png"
                    alt="phone"
                  /> -->
                  <!-- <i class="fa fa-envelope" aria-hidden="true"></i>
                  <div class="number">
                    {{staffDetails.contactDetails.personalEmailId}}
                  </div>
                </div> -->
              <!-- </div> -->
            </div>
            <!-- <div class="update-btn">
                <button>Update</button>
            </div> -->
          </div>
          <hr>
          <div class="text-password" (click)="changePassword()">
            Change Password
          </div>
          <div class="text-password" (click)="logout()">
            Logout
          </div>
    </div>
    <ng-template #loader>
      <div class="prof-details">
          <img src="./../../../../../assets/images/common/istharaloader.gif" alt="" width="60%">
      </div>
    </ng-template>
</section>