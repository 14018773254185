import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertCurrency'
})
export class ConvertCurrencyPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return this.convertToString(value);
  }

  convertToString(value) {
    let val = Math.abs(value)
    let stringValue: string = val.toString();
    if (val >= 10000000) {
      stringValue = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      stringValue = (val / 100000).toFixed(2) + ' Lac';
    }
    return stringValue;
  }

}
