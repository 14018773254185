import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PopupDbService } from './../../../authentication/services/popup-db.service';
@Component({
  selector: 'app-config-panel',
  templateUrl: './config-panel.component.html',
  styleUrls: ['./config-panel.component.scss'],
})
export class ConfigPanelComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dbService: PopupDbService
  ) { }

  rotate: number = 0;

  @Input() expantion: boolean;
  @Input() srcWidth;
  configPanel = [
    // {
    //   name: 'Configurations',
    //   value: 'configuration',
    //   iconName: 'configuration',
    //   upDown: false,
    // },
    {
      active: false,
      name: 'Location',
      value: 'configuration',
      urlCheck: 'Location',
      iconName: 'location',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'City',
          value: 'city',
          active: false,
          queryPath: {
            subPath: 'city',
            main: 'Location',
            config: 'configuration',
          },
          list: []
        },
        {
          name: 'Locality',
          value: 'locality',
          active: false,
          queryPath: {
            subPath: 'locality',
            main: 'Location',
            config: 'configuration',
          },
          list: []
        },
        {
          name: 'Landmark',
          value: 'landmark',
          active: false,
          queryPath: {
            subPath: 'landmark',
            main: 'Location',
            config: 'configuration',
          },
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Roles',
      value: 'configuration',
      urlCheck: 'roles',
      iconName: 'config-staff',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Department',
          value: 'department',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Designation',
          value: 'designation',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Amenities',
      value: 'configuration',
      urlCheck: 'amenities',
      iconName: 'config-amenities',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Categories',
          value: 'Categories',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Amenity',
          value: 'Amenity',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Rules',
      value: 'configuration',
      urlCheck: 'rules',
      iconName: 'config-rules',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Categories',
          value: 'categories',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Rules',
          value: 'rules',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Helpdesk',
      value: 'configuration',
      urlCheck: 'helpdesk',
      iconName: 'config-helpdesk',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Categories',
          value: 'category',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Issue',
          value: 'issue',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Food',
      value: 'configuration',
      urlCheck: 'food',
      iconName: 'config-food',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Cuisine',
          value: 'cuisine',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Categories',
          value: 'category-food',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Sub-Categories',
          value: 'sub-category-food',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Items',
          value: 'food-items',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Menu Categories',
          value: 'menu-categories',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Customization',
          value: 'customization-categories',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Automation',
      value: 'configuration',
      urlCheck: 'automation',
      iconName: 'config-automation',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Category',
          value: 'automation-category',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Sub Category',
          value: 'automation-subcategory',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Check List',
          value: 'checklist',
          active: false,
          queryPath: null,
          list: []
        },
        // {
        //   name: 'Forms',
        //   value: 'forms',
        //   active: false,
        //   queryPath: false,
        // },
        {
          name: 'Template',
          value: 'template',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Trigger',
          value: 'trigger',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'College Config',
      value: 'configuration',
      urlCheck: 'college-config',
      iconName: 'collge-config',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Department',
          value: 'department-college',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Specialization',
          value: 'specialization-college',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Leave Reasons',
          value: 'leave-reasons',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Food Court',
      value: 'configuration',
      urlCheck: 'food-court',
      iconName: 'food-court',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Site Type',
          value: 'list-site-type',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Sales Item Category',
          value: 'list-item-category',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
  ];

  subpath;
  main;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.subPath || params.main) {
        this.subpath = params.subPath;
        this.main = params.main;
      } else {
        let currentUrl = window.location.href;
        let parts = currentUrl.split('/');
        let position = parts.indexOf('erp');
        parts = parts.slice(position);
        this.main = parts[3];
        this.subpath = parts[4];
      }
    });

    this.pathChecking();
  }

  pathChecking() {
    console.log(this.main, this.subpath);

    this.configPanel.forEach((element) => {
      if (element.urlCheck === this.main) {
        element.upDown = true;
        element.active = true;
        if (element.list?.length > 0) {
          element.list.forEach((configList) => {
            if (configList.value === this.subpath) {
              configList.active = true;
            }
          });
        }
      } else {
        element.upDown = false;
      }
    });
  }

  makeModulesInActive() {
    this.configPanel.forEach((sp1, itemIndex) => {
      sp1.active = false;
      sp1.upDown = false;

      if (sp1.list.length > 0) {
        sp1.list.forEach((sp2) => {
          sp2.active = false;
        })
      }
    })
  }

  onFirstLevelList(panelIndex) {
    let index = 0;

    for (let firstlevelItem of this.configPanel) {
      if (panelIndex === index) {
        if (firstlevelItem.list.length > 0) {
          firstlevelItem.upDown = !firstlevelItem.upDown;
        }
        // else {
        //   this.makeModulesInActive();

        //   firstlevelItem.active = true;

        //   if (firstlevelItem.queryPath) {
        //     this.router.navigate([`/erp/erp-isthara/${firstlevelItem.value}`], { queryParams: firstlevelItem.queryPath });
        //   } else {
        //     this.router.navigate([
        //       `/erp/erp-isthara/${firstlevelItem.value}`,
        //     ]);
        //   }

        // }
        break;
      }
      index++;
    }
  }

  onSecondLevelList(firstLevelItem, secondLevelItem, panelIndex) {
    if (secondLevelItem.list.length > 0) {
      secondLevelItem.upDown = !secondLevelItem.upDown;
    } else {
      this.makeModulesInActive();

      firstLevelItem.upDown = true;
      firstLevelItem.active = true;
      secondLevelItem.active = true;

      if (secondLevelItem.queryPath) {
        this.router.navigate(
          [
            `/erp/erp-isthara/${firstLevelItem.value}`,
          ],
          {
            queryParams: secondLevelItem.queryPath,
          }
        );
      } else {
        this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/${secondLevelItem.value}`]);
      }
    }
  }

  onIconsSelected(panelIndex) {
    let index = 0;

    for (let firstLevelItem of this.configPanel) {
      if (panelIndex === index) {
        if (firstLevelItem.list.length > 0) {
          firstLevelItem.upDown = !firstLevelItem.upDown;

          this.makeModulesInActive();

          firstLevelItem.upDown = true;
          firstLevelItem.active = true;
          firstLevelItem.list[0].active = true;

          if (firstLevelItem.list[0].queryPath) {
            this.router.navigate(
              [
                `/erp/erp-isthara/${firstLevelItem.value}`,
              ],
              {
                queryParams: firstLevelItem.list[0].queryPath,
              }
            );
          } else {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/${firstLevelItem.list[0].value}`]);
          }
        } else {
          this.makeModulesInActive();

          firstLevelItem.active = true;

        }
        break;
      }
      index++;
    }
  }

  logoclick() {
    // this.router.navigate(['/erp/erp-isthara/enquiry'],{queryParams:{subPath:"allenquiry",main:'Tenant Leads',config:false}});
  }
  goBack() {
    this.router.navigate(['/erp/erp-isthara/enquiry'], {
      queryParams: {
        subPath: 'allenquiry',
        main: 'Tenant Leads',
        config: 'main',
      },
    });
  }
}
