<section>
  <div class="main-section">
    <div class="sigin-door">
      <img src="./../../../../../assets/images/enquiry/loginwindow.svg" alt="loginwindow"/>
    </div>
    <div class="signin-card">
      <div class="logo">
        <img src="./../../../../../assets/images/side-panel/isthara-logo.svg" alt="" />
      </div>
      <div [ngSwitch]="viewMode" class="viewMode">
        <div *ngSwitchCase="'signIn'">
          <div class="signin-section">
            <div class="big-text">Log In</div>
            <div class="sub-text">
              Welcome to Isthara ! Log In with your details
            </div>
            <div class="fields">
              
              <mat-form-field class="sfull-width" appearance="outline">
                <mat-label >Enter Username</mat-label>
                <div style="display: flex; width: 100%;">
                <input matInput [(ngModel)]="staffusername" (ngModelChange)="expTestSignUp($event)" required /><mat-icon><span>perm_identity</span></mat-icon>
              </div>
              </mat-form-field>
            
              <div *ngIf="errorMessage" class="error">
                Please Enter username
              </div>
            </div>
            <div class="fields">
            <form [formGroup]="signin">
              <mat-form-field class="sfull-width" appearance="outline">
                   <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" required [(ngModel)]="staffpassword"
                   (keyup.enter)="loginBtn()">
                   <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                   <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
               </mat-form-field>
             </form>
            </div>
            <ng-template #loading>
              <div class="v-btn">
                <button class="submit-btn" (click)="loginBtn()" 
                [disabled]="(staffusername === '' || staffusername === null) ||
                (staffpassword === '' || staffpassword === null)"
                [ngClass]="staffusername != '' && staffpassword != '' ? 'submt-btn' : 'disabled-btn'">Login</button>
              </div>
            </ng-template>
            <div class="v-btn" *ngIf="loader;else loading">
              <button class="submit-btn">
                <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
              </button>
            </div>
            <!-- <div class="signup-section">
              <div class="sub-text">Don’t have an account yet?</div>
              <span class="signup" (click)="changeViewMode('signUp')">Sign Up</span>
            </div> -->
          </div>
        </div>
        <div *ngSwitchCase="'otp'">

          <div class="big-text">OTP Verification</div>
          <div class="revert">
            <!-- <img src="./../../../../assets/images/contactus/arrow.png" alt="" width="10px"> -->
            <div (click)="changeViewMode('signIn')"><span *ngIf="typefUserAuth == 'mobile'">+91</span>{{user.value}}<span class="change">change</span>


            </div>


          </div>
          <div class="sub-text">
            Your OTP has been sent to your mobile number It may take sometime to
            recieve OTP.
          </div>
          <div class="otp-field">
            <!-- <input
              id="codeBox1"
              type="text"
              pattern="\d*"
              #num1
              maxlength="1"
              (keyup)="move(num1, num2, $event)"
              (keydown)="back(num1,num1,$event)"
              [(ngModel)]="verifyotp.otp1"
            />
            <input
              id="codeBox2"
              #num2
              type="text"
              pattern="\d*"
              maxlength="1"
              (keyup)="move(num2, num3, $event)"
              (keydown)="back(num2,num1,$event)"
              [(ngModel)]="verifyotp.otp2"
            />
            <input
              id="codeBox3"
              #num3
              type="text"
              pattern="\d*"
              maxlength="1"
              (keyup)="move(num3, num4, $event)"
              (keydown)="back(num3,num2,$event)"
              [(ngModel)]="verifyotp.otp3"
            />
            <input
              id="codeBox4"
              #num4
              type="text"
              pattern="\d*"
              maxlength="1"
              (keyup)="move(num4, num5, $event)"
              (keydown)="back(num4,num3,$event)"
              [(ngModel)]="verifyotp.otp4"
            />
            <input
              id="codeBox5"
              #num5
              type="text"
              pattern="\d*"
              maxlength="1"
              (keyup)="move(num5, num6, $event)"
              (keydown)="back(num5,num4,$event)"
              [(ngModel)]="verifyotp.otp5"
            />
            <input
              id="codeBox6"
              #num6
              type="text"
              pattern="\d*"
              maxlength="1"
              [(ngModel)]="verifyotp.otp6"
              (keyup)="move(num6, num6, $event)"
              (keydown)="back(num6,num5,$event)"
            /> -->
            <ng-otp-input (onInputChange)="onOtpEnter($event)" [config]="{length:6}" [config]="config"></ng-otp-input>
          </div>
          <div class="timer pull-right">
            <countdown #countdown [config]="{ leftTime: 120, format: 'mm:ss' }" (event)="handleCounter($event)">
            </countdown>
          </div>
          <div class="resend-otp">
            <div class="sub-text">Didn't get OTP?</div>
            <button class="resendBtn" [disabled]="counterAction === 'start'" (click)="resend()">
              Resend Now
            </button>
          </div>
          <div class="v-btn">
            <button class="verify-btn" (click)="verifyBtn()">Verify Now</button>
          </div>
        </div>
        <div *ngSwitchCase="'signUp'">
          <div class="big-text">Sign Up</div>
          <div class="sub-text" style="font-size: 12px">
            Create an account to start using Isthara
          </div>
          <div class="fields">
            <mat-form-field class="sfull-width" appearance="outline">
              <mat-label>Full Name</mat-label>
              <input matInput [(ngModel)]="user.fullName" #fullnameCheck="ngModel" required />
              <mat-error *ngIf="
                  !fullnameCheck.valid &&
                  (fullnameCheck.dirty || fullnameCheck.touched)
                ">
                Please Enter your full name
              </mat-error>
            </mat-form-field>
            <mat-form-field class="sfull-width" appearance="outline">
              <mat-label>Mobile Number</mat-label>
              <input matInput [(ngModel)]="user.mobile" #mobileCheck="ngModel" pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
                required />
              <mat-error *ngIf="
                  !mobileCheck.valid &&
                  (mobileCheck.dirty || mobileCheck.touched)
                ">
                Please Enter valide mobile number
              </mat-error>
            </mat-form-field>
            <mat-form-field class="sfull-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="user.email" #emailCheck="ngModel"
                pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$" required />
              <mat-error *ngIf="
                  !emailCheck.valid && (emailCheck.dirty || emailCheck.touched)
                ">
                Please Enter Valid Email Id
              </mat-error>
            </mat-form-field>
            <div class="v-btn">
              <button class="verify-btn" (click)="registerBtn()">
                Sign Up
              </button>
            </div>
            <div class="signup-section">
              <div class="sub-text">Already have an account?</div>
              <span class="signup" (click)="changeViewMode('signIn')">Sign In</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>