import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  constructor(
    public dialog: MatDialog,
    private http: HttpClient,

    private snackbar: MatSnackBar
  ) {}


  private pageNoSnapshot: number = 0;
  private sizeSnapshot: number = 10;
  private isInsideEdit: boolean = false;

  getPageNo() {
    return this.pageNoSnapshot;
  }
  setPageNo(pageNo) {
    this.pageNoSnapshot = pageNo;
  }

  getSizeSanpshot() {
    return this.sizeSnapshot;
  }
  setSizeSnapshot(size) {
    this.sizeSnapshot = size;
  }

  getIsInsideEdit() {
    return this.isInsideEdit;
  }
  setIsInsideEdit(value) {
    this.isInsideEdit = value;
  }

  
  openDialog(componentName, height, width, dataModel) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
    });
  }
  openMessageDialog(componentName, height, width, dataModel, maxWidth) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      maxWidth: maxWidth,
      disableClose: true,
    });
  }
  openSnack(message, action) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  createEnquiry(data) {
    return this.http.post(
      environment.baseUrl + `/enquiry/enquiry/tenant/`,
      data
    );
  }

  getEnquiryList(isMyLead, isOldLead, pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/enquiry/enquiry/tenant/?isMyLeads=${isMyLead}&isOldLeads=${isOldLead}&pageNo=${pageNo}&size=${size}`
    );
  }

  callLog(data) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/${data.leadId}/calllog`,
      data
    );
  }

  assignStaff(leadId) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/${leadId.leadId}/staff`,
      leadId
    );
  }

  updateStatus(leadId) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/${leadId.leadId}/status`,
      leadId
    );
  }

  search(data) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/search`,
      data
    );
  }

  // getStaff(allDetails,pageNo,size) {
  //   return this.http.get(environment.baseUrl +`/user/staff/filtered?allDetails=${allDetails}&pageNo=${pageNo}&size=${size}`);
  // }
  getStaff(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/user/staff/ist/all?pageNo=${pageNo}&size=${size}&staffType=${'COLIVING'}`
    );
  }

  getStaffById(staffId) {
    return this.http.get(
      environment.baseUrl + `/user/staff/ist?staffId=${staffId}`
    );
  }
  getstaffbymobilenumber(mobilenumber) {
    return this.http.get(
      environment.baseUrl +
        `/user/staff/ist/mobile/staff?mobile=${mobilenumber}`
    );
  }
  sendMessage(leadId) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/${leadId.leadId}/message`,
      leadId
    );
  }

  deleteEnquiry(leadId) {
    return this.http.delete(
      environment.baseUrl + `/enquiry/enquiry/tenant/${leadId}`
    );
  }

  deleteStaff(staffId) {
    return this.http.put(
      environment.baseUrl + `/user/staff/${staffId}`,
      staffId
    );
  }

  editEnquiry(data) {
    return this.http.put(
      environment.baseUrl + `/enquiry/enquiry/tenant/`,
      data
    );
  }

  editStaff(data) {
    return this.http.put(environment.baseUrl + `/user/staff`, data);
  }

  createStaff(data) {
    return this.http.post(environment.baseUrl + `/user/staff`, data);
  }

  resetpassword(adminId, adminpw, confirmpw, newpsw, staffID) {
    return this.http.put(
      environment.baseUrl +
        `/user/staff/admin/password?adminId=${adminId}&adminPassword=${adminpw}&confirmNewPassword=${confirmpw}&newPassword=${newpsw}&staffId=${staffID}`,
      staffID
    );
  }

  getPropertyForEnquiry() {
    return this.http.get(
      environment.baseUrl + `/enquiry/enquiry/tenant/ist/properties`
    );
  }
  getlocalitiesonproperty(localities) {
    return this.http.get(
      environment.baseUrl +
        `/property/master/ist/property/locality?location=${localities}`
    );
  }

  filterDialog(componentName, panelClass, height, width, position, data) {
    return this.dialog.open(componentName, {
      panelClass: panelClass,
      width: width,
      height: height,
      position: position,
      data: data,
      disableClose: true,
    });
  }

  terminatestaffbyId(forceterminate, staffID, lastworkday, reason) {
    return this.http.put(
      environment.baseUrl +
        `/user/staff/terminate?forceTerminate=${forceterminate}&id=${staffID}&lastWorkingDay=${lastworkday}&reason=${reason}`,
      staffID
    );
  }

  // filterstaff(alldetails,city,department,employmentType,locality,propertyName,status,staffId){
  //   return this.http.get(environment.baseUrl+`/user/staff/filtered?allDetails=${alldetails}&city=${city}&department=${department}&employmentType=${employmentType}&locality=${locality}&propertyName=${propertyName}&staffId=${staffId}&status=${status}`)
  // }

  filterstaff(details) {
    return this.http.put(environment.baseUrl + `/user/staff/filtered`, details);
  }
  getuserpermission(staffId) {
    return this.http.get(
      environment.baseUrl + `/user/rbac/permission/${staffId}`
    );
  }

  getallpropertyByCity(city) {
    return this.http.get(
      environment.baseUrl + `/property/master/locality?city=${city}`
    );
  }

  getalllocalityBycity(data) {
    return this.http.put(
      environment.baseUrl + `/property/master/ist/citylist/localities`,
      data
    );
  }

  searchStaff(name) {
    return this.http.get(
      environment.baseUrl + `/user/staff/ist/search?name=${name}`
    );
  }
  updateStaffPermissions(data) {
    return this.http.put(environment.baseUrl + `/user/rbac/permission`, data);
  }

  getonedesignation(department, designation) {
    return this.http.get(
      environment.baseUrl +
        `/user/designations/v1/ist/get/dept?department=${department}&designation=${designation}`
    );
  }

  getpropertyonlocality(data) {
    return this.http.put(
      environment.baseUrl + `/property/master/ist/locality/properties`,
      data
    );
    // return this.http.put(
    //   environment.baseUrl + `/property/master/ist/locality/properties/student?value=${true}`,
    //   data
    // );
  }

  getAllDesignation() {
    return this.http.get(environment.baseUrl + `/user/designations/v1/all`);
  }

  getAllDepartments() {
    return this.http.get(
      environment.baseUrl +
        '/user/configurations/v1/ist/get?pageNo=0&size=10000'
    );
  }

  // getAllHelpdeskCategories() {
  //   return this.http.get(
  //     environment.baseUrl + '/property/helpdesk/category/ist/v1/all-categories'
  //   );
  // }

  getuserPermissions(staffId) {
    return this.http.get(
      environment.baseUrl + `/user/rbac/permission/${staffId}`
    );
  }

  getallCity() {
    return this.http.get(environment.baseUrl + `/property/master/ist/all`);
  }

  getdesignationbydepartment(designation) {
    return this.http.get(
      environment.baseUrl +
        `/user/configurations/v1/ist/get/${designation}/designations`
    );
  }

  getallsites() {
    return this.http.get(environment.baseUrl + `/foodcourt/site/ist`);
  }

  getfoodcourtsbysiteids(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/site/ist/foodCourts`,
      data
    );
  }

  getstaffpaginatedapi(pageNo, sizeNo) {
    return this.http.get(
      environment.baseUrl +
        `/user/staff/v1/ist/paged?pageNo=${pageNo}&size=${sizeNo}`
    );
  }

  changeStaffStatus(staffId: string, status: string, rejectReason: string = '') {
    if (rejectReason) {
      return this.http.put(
        environment.baseUrl + `/operationteam/ist/change/staff/status?staffId=${staffId}&status=${status}&rejectReason=${rejectReason}`, {}
      )
    } else {
      return this.http.put(
        environment.baseUrl + `/operationteam/ist/change/staff/status?staffId=${staffId}&status=${status}`, {}
      )
    }
  }
}
