import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'ng2-charts';
import { PopupDbService } from '../../../authentication/services/popup-db.service';
import { SigninSignupServicesService } from '../../../authentication/services/signin-signup-services.service';
import { SiteInfoPopupComponent } from '../../../major-erp-components/student-living/student-living-components/Institutions/institutions-sub-components/site-info-popup/site-info-popup.component';
import { StudentLivingService } from '../../../major-erp-components/student-living/student-living.service';

@Component({
  selector: 'app-student-config-panel',
  templateUrl: './student-config-panel.component.html',
  styleUrls: ['./student-config-panel.component.scss'],
})
export class StudentConfigPanelComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dbService: PopupDbService,
    private institutionService: SigninSignupServicesService,
    private studentLivingService: StudentLivingService
  ) { }

  main;
  subpath;
  institutionId: string;

  ngOnInit(): void {
    // let currentUrl = window.location.href;
    this.institutionId = this.dbService.getInstitutionId();
    this.getInstitutionDetails(this.institutionId);
    let currentUrl = window.location.pathname;
    let parts = currentUrl.split('/');

    this.main = parts[parts.length - 2];
    this.subpath = parts[parts.length - 1];
    this.pathChecking();
    console.log(this.main, this.subpath);
  }

  rotate: number = 0;
  @Input() expantion: boolean;
  @Input() srcWidth;

  configPanel = [
    {
      active: false,
      name: 'Academic Config',
      urlCheck: 'academic-config',
      value: 'institution-configuration',
      iconName: 'collge-config',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Courses',
          value: 'list-courses',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Specialization',
          value: 'list-specialization',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Attendance Config',
      urlCheck: 'attendance-config',
      value: 'institution-configuration',
      iconName: 'collge-config',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Attendance Site',
          value: 'list-attendance-site',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Device Info',
          value: 'list-device-info',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
  ];

  institutionDetails: any = {};
  getInstitutionDetails(id) {
    this.institutionService.getInstitute(id).subscribe(
      (res: any) => {
        this.institutionDetails = res.data;
      },
      (err) => {
        // console.log("ERROR: ",err);
      }
    );
  }

  showInstitutionDetails() {
    // console.log("SUTDDS:KFJL :    ", this.institutionDetails);

    let callModel = this.studentLivingService.openMessageDialog(
      SiteInfoPopupComponent,
      'auto',
      'auto',
      this.institutionDetails,
      '97vw'
    );
  }

  pathChecking() {
    this.configPanel.forEach((element) => {
      if (element.urlCheck === this.main) {
        element.upDown = true;
        element.active = true;
        if (element.list?.length > 0) {
          element.list.forEach((configList) => {
            if (configList.value === this.subpath) {
              configList.active = true;
            }
          });
        }
      } else {
        element.upDown = false;
        let currentUrl = window.location.href.split('/');
        // // console.log(currentUrl[currentUrl.length-1]);
        if (element.list.length > 0) {
          element.list.forEach((subele) => {
            if (subele.value === currentUrl[currentUrl.length - 1]) {
              element.upDown = true;
              subele.active = true;
            }
          });
        }
      }
    });
  }

  makeModulesInActive() {
    this.configPanel.forEach((sp1, itemIndex) => {
      sp1.active = false;

      if (sp1.list.length > 0) {
        sp1.upDown = false;

        sp1.list.forEach((sp2) => {
          sp2.active = false;
        })
      }
    })
  }

  onFirstLevelList(panelIndex) {
    let index = 0;

    for (let firstlevelItem of this.configPanel) {
      if (panelIndex === index) {
        if (firstlevelItem.list.length > 0) {
          firstlevelItem.upDown = !firstlevelItem.upDown;
        } else {
          this.makeModulesInActive();

          firstlevelItem.active = true;

          if (firstlevelItem.queryPath) {
            this.router.navigate([`/erp/erp-isthara/${firstlevelItem.value}/${firstlevelItem.urlCheck}/list-${firstlevelItem.urlCheck}`], { queryParams: firstlevelItem.queryPath });
          } else {
            this.router.navigate([
              `/erp/erp-isthara/${firstlevelItem.value}/${firstlevelItem.urlCheck}/list-${firstlevelItem.urlCheck}`,
            ]);
          }

        }
        break;
      }
      index++;
    }
  }

  onSecondLevelList(firstLevelItem, secondLevelItem, panelIndex) {
    if (secondLevelItem.list.length > 0) {
      secondLevelItem.upDown = !secondLevelItem.upDown;
    } else {
      this.makeModulesInActive();

      firstLevelItem.upDown = true;
      firstLevelItem.active = true;
      secondLevelItem.active = true;

      if (secondLevelItem.queryPath) {
        this.router.navigate(
          [
            `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`,
          ],
          {
            queryParams: secondLevelItem.queryPath,
          }
        );
      } else {
        this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/${secondLevelItem.value}`]);
      }
    }
  }

  onIconsSelected(panelIndex) {
    let index = 0;

    for (let firstLevelItem of this.configPanel) {
      if (panelIndex === index) {
        if (firstLevelItem.list.length > 0) {
          firstLevelItem.upDown = !firstLevelItem.upDown;

          this.makeModulesInActive();

          firstLevelItem.upDown = true;
          firstLevelItem.active = true;
          firstLevelItem.list[0].active = true;

          if (firstLevelItem.list[0].queryPath) {
            this.router.navigate(
              [
                `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`,
              ],
              {
                queryParams: firstLevelItem.list[0].queryPath,
              }
            );
          } else {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/${firstLevelItem.list[0].value}`]);
          }
        } else {
          this.makeModulesInActive();

          firstLevelItem.active = true;

          if (firstLevelItem.queryPath) {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`], { queryParams: firstLevelItem.queryPath });
          } else {
            this.router.navigate([
              `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`,
            ]);
          }
        }
        break;
      }
      index++;
    }
  }

  logoclick() {
    // this.router.navigate(['/erp/erp-isthara/enquiry'],{queryParams:{subPath:"allenquiry",main:'Tenant Leads',config:false}});
  }

  goBack() {
    this.router.navigate(
      ['/erp/erp-isthara/institution-details/student/list-student'],
      {
        queryParams: {
          institutionId: this.institutionId,
          config: 'studentLiving',
        },
      }
    );
  }
}
