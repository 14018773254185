<section id="side-panel">
  <div class="side-panel-box">
    <div class="logo">
      <img
        src="./../../../../../../assets/images/side-panel/isthara-logo.svg"
        loading="lazy"
        alt="logo"
        class="logo"
        *ngIf="expantion"
        (click)="logoclick()"
      />
      <img
        src="./../../../../../../assets/images/side-panel/isthara-logo.svg"
        loading="lazy"
        alt="logo"
        width="100%"
        *ngIf="!expantion"
        (click)="logoclick()"
      />
    </div>
    <div [ngClass]="expantion ? 'side-section-panel' : 'not-expanded'">
      <ng-container *ngFor="let firstLevelItem of sidepanel; let i = index">
        <ng-container *ngIf="expantion; else Icons">
          <section [id]="firstLevelItem?.id">
            <div
              class="icon-bar"
              [ngClass]="expantion === false ? 'dropdown' : ''"
              (click)="onFirstLevelList(i)"
            >
              <div class="side-panel-img">
                <img
                  *ngIf="!firstLevelItem.active"
                  src="./../../../../../../assets/images/side-panel/{{
                    firstLevelItem.value
                  }}.svg"
                  alt="dashboard"
                  loading="lazy"
                  width="100%"
                />
                <img
                  *ngIf="firstLevelItem.active"
                  src="./../../../../../../assets/images/side-panel/{{
                    firstLevelItem.value
                  }}_active.svg"
                  alt="dashboard"
                  loading="lazy"
                  width="100%"
                />
              </div>
              <div
                class="side-panel-text"
                [matTooltip]="firstLevelItem.name"
                matTooltipPosition="before"
              >
                <span
                  [ngClass]="firstLevelItem.active ? 'dark' : 'default-text'"
                  class="item-name"
                  >{{ firstLevelItem.name }}</span
                >
                <span *ngIf="firstLevelItem.list?.length > 0" class="item-arrow"
                  ><img
                    src="./../../../../../../assets/images/side-panel/arrow-right.svg"
                    alt="arrow"
                    loading="lazy"
                    width="7px"
                    class="arrow"
                    [ngClass]="
                      !firstLevelItem.upDown ? 'normal-arrow' : 'rotated-arrow'
                    "
                  />
                </span>
              </div>
            </div>
            <ul
              class="list-container"
              *ngIf="firstLevelItem.list?.length > 0 && firstLevelItem.upDown"
            >
              <ng-container
                *ngFor="
                  let secondLevelItem of firstLevelItem.list;
                  let j = index
                "
              >
                <li
                  side-panel-text
                  (click)="
                    onSecondLevelList(firstLevelItem, secondLevelItem, j)
                  "
                  [id]="secondLevelItem?.id"
                  [matTooltip]="secondLevelItem.name"
                  matTooltipPosition="before"
                >
                  <span class="default-text" *ngIf="!secondLevelItem.active">
                    <mat-icon>panorama_fish_eye</mat-icon> &nbsp;
                    {{ secondLevelItem.name | titlecase }}
                  </span>

                  <span class="dark" *ngIf="secondLevelItem.active">
                    <mat-icon>lens</mat-icon> &nbsp;
                    {{ secondLevelItem.name | titlecase }}
                  </span>
                  <span *ngIf="secondLevelItem.list?.length > 0">
                    <img
                      src="./../../../../../assets/images/side-panel/arrow-right.svg"
                      alt="arrow"
                      loading="lazy"
                      width="7px"
                      class="arrow"
                      [ngClass]="
                        secondLevelItem.upDown
                          ? 'rotated-arrow'
                          : 'normal-arrow'
                      "
                    />
                  </span>
                </li>
                <ul
                  class="list-container"
                  *ngIf="
                    secondLevelItem.list?.length > 0 && secondLevelItem.upDown
                  "
                >
                  <ng-container
                    *ngFor="
                      let thirdLevelItem of secondLevelItem.list;
                      let j = index
                    "
                  >
                    <li
                      side-panel-text
                      (click)="
                        onThirdLevelList(
                          firstLevelItem,
                          secondLevelItem,
                          thirdLevelItem,
                          k
                        )
                      "
                      [id]="thirdLevelItem?.id"
                      [matTooltip]="thirdLevelItem.name"
                      matTooltipPosition="before"
                    >
                      <span class="default-text" *ngIf="!thirdLevelItem.active">
                        <mat-icon>panorama_fish_eye</mat-icon> &nbsp;
                        {{ thirdLevelItem.name | titlecase }}
                      </span>

                      <span class="dark" *ngIf="thirdLevelItem.active">
                        <mat-icon>lens</mat-icon> &nbsp;
                        {{ thirdLevelItem.name | titlecase }}
                      </span>
                      <span *ngIf="thirdLevelItem.list?.length > 0">
                        <img
                          src="./../../../../../assets/images/side-panel/arrow-right.svg"
                          alt="arrow"
                          loading="lazy"
                          width="7px"
                          class="arrow"
                          [ngClass]="
                            thirdLevelItem.upDown
                              ? 'rotated-arrow'
                              : 'normal-arrow'
                          "
                        />
                      </span>
                    </li>
                    <ul
                      class="list-container"
                      *ngIf="
                        thirdLevelItem.list?.length > 0 && thirdLevelItem.upDown
                      "
                    >
                      <ng-container
                        *ngFor="
                          let fourthLevelItem of thirdLevelItem.list;
                          let j = index
                        "
                      >
                        <li
                          side-panel-text
                          (click)="
                            onFourthLevelList(
                              firstLevelItem,
                              secondLevelItem,
                              thirdLevelItem,
                              fourthLevelItem,
                              k
                            )
                          "
                          [id]="fourthLevelItem?.id"
                          [matTooltip]="fourthLevelItem.name"
                          matTooltipPosition="before"
                        >
                          <span
                            class="default-text"
                            *ngIf="!fourthLevelItem.active"
                          >
                            <mat-icon>panorama_fish_eye</mat-icon> &nbsp;
                            {{ fourthLevelItem.name | titlecase }}
                          </span>

                          <span class="dark" *ngIf="fourthLevelItem.active">
                            <mat-icon>lens</mat-icon> &nbsp;
                            {{ fourthLevelItem.name | titlecase }}
                          </span>

                          <span *ngIf="fourthLevelItem.list?.length > 0">
                            <img
                              src="./../../../../../assets/images/side-panel/arrow-right.svg"
                              alt="arrow"
                              loading="lazy"
                              width="7px"
                              class="arrow"
                              [ngClass]="
                                fourthLevelItem.upDown
                                  ? 'rotated-arrow'
                                  : 'normal-arrow'
                              "
                            />
                          </span>
                        </li>
                      </ng-container>
                    </ul>
                  </ng-container>
                </ul>
              </ng-container>
            </ul>
          </section>
        </ng-container>
        <ng-template #Icons>
          <div
            class="small-side-panel-img"
            [matTooltip]="firstLevelItem.name"
            matTooltipPosition="before"
            (click)="onIconsSelected(i)"
          >
            <img
              *ngIf="!firstLevelItem.active"
              src="./../../../../../../assets/images/side-panel/{{
                firstLevelItem.value
              }}.svg"
              alt="dashboard"
              loading="lazy"
              width="100%"
            />
            <img
              *ngIf="firstLevelItem.active"
              src="./../../../../../../assets/images/side-panel/{{
                firstLevelItem.value
              }}_active.svg"
              alt="dashboard"
              loading="lazy"
              width="100%"
            />
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</section>
<div *ngIf="configuration === 'food-court'">
  <app-side-panel [panelName]="configuration"></app-side-panel>
</div>
