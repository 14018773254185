<section style="position: relative;">
  <img src="../../../../../assets/images/common/close.svg" alt="" class="close-panel-img" (click)="closeSidePanel()"
    *ngIf="srcWidth <= 475">
  <div *ngIf="configuration === 'main'">
    <app-main-panel [expantion]="expantion" [srcWidth]="srcWidth"></app-main-panel>
  </div>
  <div *ngIf="configuration === 'configuration'">
    <app-config-panel [expantion]="expantion" [srcWidth]="srcWidth"></app-config-panel>
  </div>
  <div *ngIf="configuration === 'studentLiving'">
    <app-student-living-panel [expantion]="expantion" [srcWidth]="srcWidth"></app-student-living-panel>
  </div>
  <div *ngIf="configuration === 'food-court'">
    <app-foodcourt-panel [expantion]="expantion" [srcWidth]="srcWidth"></app-foodcourt-panel>
  </div>
  <div *ngIf="configuration === 'institution-foodcourt'">
    <app-institution-vendor [expantion]="expantion" [srcWidth]="srcWidth"></app-institution-vendor>
  </div>
  <div *ngIf="configuration === 'institution-configuration'">
    <app-student-config-panel [expantion]="expantion" [srcWidth]="srcWidth"></app-student-config-panel>
  </div>
</section>
