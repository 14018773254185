import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from './../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StudentLivingService {
  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  private isFromRelPage: boolean = null;
  private currPageNo: number = 0;
  private currSize: number = 10;

  getCurrPageNo() {
    return this.currPageNo;
  }
  setCurrPageNo(pageNo) {
    return (this.currPageNo = pageNo);
  }

  getCurrSize() {
    return this.currSize;
  }
  setCurrSize(size) {
    this.currSize = size;
  }

  getIsFromRelPage() {
    return this.isFromRelPage;
  }
  setIsFromRelPage(relPage) {
    this.isFromRelPage = relPage;
  }

  openDialog(componentName, height, width, dataModel) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: false,
    });
  }

  getallCity() {
    return this.http.get(environment.baseUrl + `/property/master/ist/all`);
  }

  getalllocalityBycity(data) {
    console.log('data ', data);
    return this.http.put(
      environment.baseUrl + `/property/master/ist/citylist/localities`,
      data
    );
  }
  getInstitutionList() {
    return this.http.get(
      environment.baseUrl + '/student/institution/ist/v1/all?pageNo=0&size=10'
    );
  }
  openSnack(message, action) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  getallinstitutions(pageNo, size) {
    return this.http.get(
      // environment.baseUrl+`/student/student/ist/institute/get?pageNo=${pageNo}&size=${size}`
      environment.baseUrl +
        `/student/institution/ist/v1/all?pageNo=${pageNo}&size=${size}`
    );
  }

  createinstitutions(data) {
    return this.http.post(
      environment.baseUrl + `/student/institution/ist/v1/create`,
      data
    );
  }

  updateinstitutions(data) {
    console.log('DATA => : ', data);
    return this.http.put(
      // environment.baseUrl+`/student/student/ist/update/institute`,data
      environment.baseUrl + '/student/institution/ist/v1/update',
      data
    );
  }

  deleteinstitutions(institutionId) {
    // environment.baseUrl+`/student/student/ist/delete/institute/${institutionId}
    let data = {};
    return this.http.put(
      environment.baseUrl +
        `/student/institution/ist/v1/delete/${institutionId}`,
      data
    );
  }

  deletebulkinstitutions(data) {
    console.log(data);
    return this.http.put(
      environment.baseUrl + `/student/institution/ist/v1/delete`,
      data
    );
  }

  bulkActiveinstitutions(data) {
    console.log(data);
    return this.http.put(
      environment.baseUrl + `/student/institution/ist/v1/update/status/all`,
      data
    );
  }

  getinstitutionsId(institutionId) {
    return this.http.get(
      // environment.baseUrl+`/student/student/ist/institute/get/${institutionId}`
      environment.baseUrl + `/student/institution/ist/v1/get/${institutionId}`
    );
  }

  searchinstitutions(searchdata, pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/student/institution/ist/v1/search?name=${searchdata}&pageNo=${pageNo}&size=${size}`
      // environment.baseUrl+'/student/institute/ist/institute/ist/searchInstitute?name='
    );
  }

  institutionstatus(institutionId, activevalue, data) {
    return this.http.put(
      // environment.baseUrl+`/student/student/ist/updatestatus/institute/${institutionId}?value=${activevalue}`,data
      environment.baseUrl +
        `/student/institution/ist/v1/toggle/status/${institutionId}?value=${activevalue}`,
      data
    );
  }

  setInstitutionStatus(body: any) {
    localStorage.setItem('institutionStatus', JSON.stringify(body));
  }

  bulkUploadinstitutions(data, staffId, staffName) {
    console.log('file data', data);
    return this.http.post(
      environment.baseUrl +
        `/student/institution/ist/upload?staffId=${staffId}&staffName=${staffName}`,
      data
    );
  }

  filterinstitutions(data, pageNo, size) {
    // environment.baseUrl+`/student/student/ist/filter/institute`,data
    return this.http.put(
      environment.baseUrl +
        `/student/institution/ist/v1/filter/paged?pageNo=${pageNo}&size=${size}`,
      data
    );
  }

  exportinstitutionexcel(data) {
    return this.http.put(
      environment.baseUrl + `/student/institution/ist/download`,
      data,
      { observe: 'response', responseType: 'blob' }
    );
  }

  getspecialization() {
    return this.http.get(
      environment.baseUrl + `/user/college/config/ist/specializations/v1`
    );
  }

  filterstaff() {
    let details = {
      size: 100,
    };
    return this.http.put(environment.baseUrl + `/user/staff/filtered`, details);
  }
  getAllStaff() {
    return this.http.get(
      environment.baseUrl +
        `/user/staff/ist/all?pageNo=0&size=100&staffType=STUDENT_LIVING`
    );
  }

  openMessageDialog(componentName, height, width, dataModel, maxWidth) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      maxWidth: maxWidth,
      disableClose: true,
    });
  }
  closeMessageDialog() {
    this.dialog.closeAll();
  }
}
