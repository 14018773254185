import { SigninSignupFlowComponent } from './components/erp-landing/authentication/signin-signup-flow/signin-signup-flow.component';
import { LandingConsoleComponent } from './components/erp-landing/landing-console/landing-console.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    component: SigninSignupFlowComponent,
  },
  {
    path: 'erp',
    component: LandingConsoleComponent,
    children: [
      // {
      //   path: 'erp',
      //   redirectTo: 'erp-isthara',
      //   pathMatch: 'full',
      // },
      {
        path: 'erp-isthara',
        loadChildren: () =>
          import(
            './components/erp-landing/erp-landing/erp-landing.module'
          ).then((module) => module.ErpLandingModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
