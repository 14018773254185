import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { PopupDbService } from '../../authentication/services/popup-db.service';
import { SigninSignupServicesService } from '../../authentication/services/signin-signup-services.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PasswordChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dbService: PopupDbService,
    private changeService : SigninSignupServicesService,
    private router : Router
  ) { }

  adminId:''
  oldpassword = '';
  newpassword = '';
  confirmnewpassword = '';
  ngOnInit(): void {
    this.adminId = this.dbService.getStaffId();
    // this.staffId=this.data.staff.staffId;
    console.log('admintotoal',this.adminId)
    this.stafDetailsbyId(this.adminId);
    // console.log(this.data.staff.staffId)
  }
  emailId;
  stafDetailsbyId(id){
    this.changeService.staffByid(id).subscribe(
      (res:any) =>{
        this.emailId = res.data[0].contactDetails.workEmailId;
        // console.log(this.emailId);
      },
      (err) => {
        console.log(err);
      }
    )
  }
  onNoClick(): void {
    this.dialogRef.close(this.data);
  }
  signin: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3) ]),
    new_password: new FormControl('', [Validators.required, Validators.min(3) ]),
    confirm_password: new FormControl('', [Validators.required, Validators.min(3) ]),
  });
  hide = true;
  loader : boolean = false;
  get passwordInput() { return this.signin.get('password'); }

  passwordshow:boolean=true;
  conformpasswordShow:boolean=true;
  resetpassword(){
    this.loader = true;
    let data = {
      confirmNewPassword : this.confirmnewpassword,
      newPassword : this.newpassword,
      oldPassword : this.oldpassword,
      staffId :  this.adminId
    }
    this.changeService.changePassword(data).subscribe(
      (res : any) => {
        console.log(res.data);
        this.onNoClick();
        localStorage.clear();
        this.router.navigate(['/auth']);
        this.loader = false;
      },
      (err) => {
        this.changeService.openSnackBarMessage("oops...! something went wrong","ok")
        this.loader = false;
      }
    )
  }

}
