import {
  Router,
} from '@angular/router';
import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { PopupDbService } from '../authentication/services/popup-db.service';
import { SigninSignupServicesService } from '../authentication/services/signin-signup-services.service';

@Component({
  selector: 'app-landing-console',
  templateUrl: './landing-console.component.html',
  styleUrls: [
    './landing-console.component.scss',
    './mq-landing-console.component.scss',
  ],
})
export class LandingConsoleComponent implements OnInit {
  @ViewChild('txt') txt: ElementRef;
  @ViewChild('profile') profile: ElementRef;
  @ViewChild('clicked') clicked: ElementRef;
  loadingRouteConfig: boolean;
  constructor(
    private dbService: PopupDbService,
    private router: Router,
    private apiService: SigninSignupServicesService,
  ) {
    this.getScreenSize();
  }
  expand = true;
  srcHeight;
  srcWidth;
  staffName;
  staffId;
  config = false;
  imgUrl;
  nameletter;
  configuration = 'main';
  ngOnInit(): void {
    this.tokenexpire();

    this.staffName = this.dbService.getUserName();
    this.staffId = this.dbService.getStaffId();

    if (this.staffId === '' || this.staffId === null) {
      this.router.navigate(['']);
    } else {
      // this.router.navigate(['/erp/erp-isthara/enquiry'],{queryParams:{subPath:'allenquiry',main:'Tenant Leads'}})
    }
    let matches = this.staffName.match(/\b(\w)/g);
    this.nameletter = matches.join('');
  }

  tokenexpire() {
    this.apiService.staffByid(this.dbService.getStaffId()).subscribe(
      (res: any) => {
        this.imgUrl = res.data[0].contactDetails.imageUrl;
        let url = this.imgUrl;
        let pattern = /^((http|https|ftp):\/\/)/;
        if (pattern.test(url)) {
          this.imgUrl = url;
        } else {
          this.imgUrl = '';
        }
        // console.log('response', this.imgUrl);
      },
      (err) => {
        if (err.error.errorCode === 1004) {
          alert(`${err.error.message}`);
          localStorage.clear();
          this.router.navigate(['/auth']);
        }
      }
    );
  }

  sidePannelToggle() {
    this.expand = !this.expand;
  }

  sidePannelopen() {
    this.expand = true;
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.srcWidth < 476) {
      if (this.txt.nativeElement.contains(event.target)) {
      } else {
        this.expand = false;
      }
    } else {
      // this.profileBox=false;
    }

    if (this.profile.nativeElement.contains(event.target)) {
    } else {
      this.profileBox = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
  }
  profileBox: boolean = false;
  openProfile() {
    this.profileBox = !this.profileBox;
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/auth']);
  }
  openConfiguration() {
    let currentPath = window.location.pathname.split('/')[3];

    if (currentPath === 'institution-details') {
      let institutionId = this.dbService.getInstitutionId();
      // alert('Institution configuration');
      this.router.navigate(
        [
          '/erp/erp-isthara/institution-configuration/academic-config/list-courses',
        ],
        {
          queryParams: {
            institutionId: institutionId,
            config: 'institution-configuration',
          },
        }
      );
    } else {
      this.configuration = 'configuration';
      this.router.navigate(['/erp/erp-isthara/configuration'], {
        queryParams: {
          subPath: 'city',
          main: 'Location',
          config: 'configuration',
        },
      });
    }
    // }
  }
}
