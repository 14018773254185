import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StudentLivingService } from '../../../../student-living.service';
import { StudentlivingImageUploadService } from '../../../../studentliving-image-upload.service';

@Component({
  selector: 'app-site-info-popup',
  templateUrl: './site-info-popup.component.html',
  styleUrls: ['./site-info-popup.component.scss']
})
export class SiteInfoPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SiteInfoPopupComponent>,
    private studentLivingService: StudentLivingService,
    private imageupload:StudentlivingImageUploadService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    ////console.log("DATA WE GOT : ",this.data);
    if (this.data.institutionContactDetails.mobile === '' || this.data.institutionContactDetails.mobile === null)
      this.data.institutionContactDetails.mobile = "N/A";
    if (this.data.institutionContactDetails.email === '' || this.data.institutionContactDetails.email === null)
      this.data.institutionContactDetails.email = "N/A";
    if (this.data.institutionContactDetails.instituteWebLink === '' || this.data.institutionContactDetails.instituteWebLink === null)
      this.data.institutionContactDetails.instituteWebLink = "N/A";
    if (this.data.addressDetails.addressLine1 === '' || this.data.addressDetails.addressLine1 === null)
      this.data.addressDetails.addressLine1 = "N/A"
  }
  closePopup() {
    this.studentLivingService.closeMessageDialog();
  }

  delete(){
    this.imageupload.deleteProfileImage(this.data.institutionImageUrl);
  }

}
