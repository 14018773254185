import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from '../../environments/environment';
import { BlobServiceClient } from '@azure/storage-blob';
@Injectable({
  providedIn: 'root',
})
export class ImageUploadAwsService {
  constructor() {}

  imageObject;
  

  async uploadProfileImage(element) {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.image.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.image.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-07-10',
      params: {
        Bucket: bucketName,
      },
    });

    // const element = e.srcElement.files[0];
    // var options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
    let imgPromise = new Promise((res, rej) => {
      if (element) {
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          s3.upload(
            {
              Key:
                'images/' +
                Math.floor(100000 + Math.random() * 900000) +
                'c' +
                new Date().getTime() +
                element.name,
              Bucket: bucketName,
              Body: element,
              ACL: 'public-read',
            },
            // options,
            function (err: any, data: any) {
              if (err) {
                rej(err);
              } else {
                res(data);
              }
            }
          );
        };
      }
    });

    return imgPromise
      .then((data) => {
        this.imageObject = data;
        console.log('awsResult', data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  // profile Imgaes

  
  async uploadFile(file: File, folderName: string) {
    const sasToken = environment.sasToken;
    const accountName = environment.accountName;
    const containerName = environment.containerName;

    const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobName = file.name;
    const blockBlobClient = containerClient.getBlockBlobClient(folderName + "/" + blobName);
  
    await blockBlobClient.uploadData(file, {
      blobHTTPHeaders: { blobContentType: file.type },
    });

    return blockBlobClient;
  }


  async uploadErpProfileImage(element) {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.image.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.image.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-07-10',
      params: {
        Bucket: bucketName,
      },
    });

    // const element = e.srcElement.files[0];

    let imgPromise = new Promise((res, rej) => {
      if (element) {
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          s3.upload(
            {
              Key:
                'erp-profile-images/' +
                Math.floor(100000 + Math.random() * 900000) +
                'c' +
                new Date().getTime() +
                element.name,
              Bucket: bucketName,
              Body: element,
              ACL: 'public-read',
            },
            function (err: any, data: any) {
              if (err) {
                rej(err);
              } else {
                res(data);
              }
            }
          );
        };
      }
    });

    return imgPromise
      .then((data) => {
        this.imageObject = data;
        console.log('awsResult', data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  //configuration images

  async uploadErpConfigImage(element) {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.image.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.image.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-07-10',
      params: {
        Bucket: bucketName,
      },
    });

    // const element = e.srcElement.files[0];

    let imgPromise = new Promise((res, rej) => {
      if (element) {
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          s3.upload(
            {
              Key:
                'configurations/' +
                Math.floor(100000 + Math.random() * 900000) +
                'c' +
                new Date().getTime() +
                element.name,
              Bucket: bucketName,
              Body: element,
              ACL: 'public-read',
            },
            function (err: any, data: any) {
              if (err) {
                rej(err);
              } else {
                res(data);
              }
            }
          );
        };
      }
    });

    return imgPromise
      .then((data) => {
        this.imageObject = data;
        console.log('awsResult', data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  //foodMenu images from master
  async uploadMasterFoodmenuImages(element) {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.image.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.image.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-07-10',
      params: {
        Bucket: bucketName,
      },
    });

    // const element = e.srcElement.files[0];

    let imgPromise = new Promise((res, rej) => {
      if (element) {
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          s3.upload(
            {
              Key:
                'master-food-items/' +
                Math.floor(100000 + Math.random() * 900000) +
                'c' +
                new Date().getTime() +
                element.name,
              Bucket: bucketName,
              Body: element,
              ACL: 'public-read',
            },
            function (err: any, data: any) {
              if (err) {
                rej(err);
              } else {
                res(data);
              }
            }
          );
        };
      }
    });

    return imgPromise
      .then((data) => {
        this.imageObject = data;
        console.log('awsResult', data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
}
