import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupDbService } from './../../../authentication/services/popup-db.service';
import { SigninSignupServicesService } from '../../../authentication/services/signin-signup-services.service';
import { SiteInfoPopupComponent } from '../../../major-erp-components/student-living/student-living-components/Institutions/institutions-sub-components/site-info-popup/site-info-popup.component';
import { StudentLivingService } from '../../../major-erp-components/student-living/student-living.service';

declare let $: any;

@Component({
  selector: 'app-student-living-panel',
  templateUrl: './student-living-panel.component.html',
  styleUrls: ['./student-living-panel.component.scss'],
})
export class StudentLivingPanelComponent implements OnInit {
  institutionID = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dbService: PopupDbService,
    private institutionService: SigninSignupServicesService,
    private studentLivingService: StudentLivingService
  ) { }
  rotate: number = 0;

  @Input() expantion: boolean;
  @Input() srcWidth;
  allConfigPanel = [
    // {
    //   name: 'Dashboard',
    //   value: 'institution-details',
    //   iconName: 'sl-dashboard',
    //   upDown: false,
    // },
    {
      active: false,
      name: 'Student',
      urlCheck: 'student',
      value: 'institution-details',
      iconName: 'sl-student',
      type: '',
      upDown: false,
      queryPath: null,
      list: []
    },
    {
      active: false,
      name: 'College Admin',
      urlCheck: 'staff',
      value: 'institution-details',
      iconName: 'sl-staff',
      type: '',
      upDown: false,
      queryPath: null,
      list: []
    },
    {
      active: false,
      name: 'Help Desk',
      urlCheck: 'helpdesk',
      value: 'institution-details',
      iconName: 'sl-helpdesk',
      type: '',
      upDown: false,
      queryPath: null,
      list: []
    },
    {
      active: false,
      name: 'Food Menu',
      urlCheck: 'foodmenu',
      value: 'institution-details',
      iconName: 'sl-foodmenu',
      type: '',
      upDown: false,
      queryPath: null,
      list: []
    },
    {
      active: false,
      name: 'Events',
      urlCheck: 'events',
      value: 'institution-details',
      iconName: 'sl-events',
      type: '',
      upDown: false,
      queryPath: null,
      list: []
    },
    // {
    //   name: 'Reports',
    //   value: 'institution-details',
    //   iconName: 'sl-reports',
    //   upDown: false,
    //   allParams:false
    // },
    {
      active: false,
      name: 'Attendance',
      value: 'institution-details',
      urlCheck: 'attendence',
      iconName: 'sl-attendance',
      type: '',
      upDown: false,
      list: [
        {
          name: 'Regular Attendance',
          value: 'sa-list',
          type: '',
          active: false,
          queryPath: { subPath: 'sa-list', main: 'attendence' },
          list: []
        },
        {
          name: 'Food Attendance',
          value: 'fma-list',
          type: '',
          active: false,
          queryPath: { subPath: 'fma-list', main: 'attendence' },
          list: []
        },
        {
          name: 'Late Night Attendance',
          value: 'lna-list',
          type: '',
          active: false,
          queryPath: { subPath: 'lna-list', main: 'attendence' },
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Leave Management',
      urlCheck: 'leavemanagement',
      value: 'institution-details',
      iconName: 'sl-leavemanagement',
      type: '',
      upDown: false,
      queryPath: null,
      list: []
    },
    {
      active: false,
      name: 'Late Pass',
      urlCheck: 'latepass',
      value: 'institution-details',
      iconName: 'sl-latepass',
      type: '',
      upDown: false,
      queryPath: null,
      list: []
    },
    {
      active: false,
      name: 'Operations Team',
      urlCheck: 'operations-team',
      value: 'institution-details',
      iconName: 'sl-operationteam',
      type: '',
      upDown: false,
      queryPath: null,
      list: []
    },
    {
      active: false,
      name: 'Property',
      urlCheck: 'property',
      value: 'institution-details',
      type: 'campus',
      iconName: 'creation',
      upDown: false,
      queryPath: null,
      list: []
    },
    // {
    //   name: 'Operations Team',
    //   urlCheck:'operationteam',
    //   value: 'institution-details',
    //   iconName: 'sl-oteam',
    //   upDown: false,
    //   allParams:false
    // },
  ];
  pathChecking() {
    this.allConfigPanel.forEach((element) => {
      if (element.urlCheck === this.main) {
        element.upDown = true;
        element.active = true;
        if (element.list?.length > 0) {
          element.list.forEach((configList) => {
            if (configList.value === this.subpath) {
              configList.active = true;
            } else {
              configList.active = false;
            }
          });
        }
      } else {
        element.upDown = false;
      }
    });
  }

  subpath;
  main;
  reloading: boolean = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!this.reloading) {
        this.reloading = true;

        if (params.subPath || params.main) {
          this.subpath = params.subPath;
          this.main = params.main;
          this.institutionID = params.institutionId;
          console.log(this.main, this.subpath);
        } else {
          let currentUrl = window.location.href;
          let parts = currentUrl.split('/');
          let position = parts.indexOf('erp');
          parts = parts.slice(position);
          this.main = parts[3];
        }
      }
    });
    this.pathChecking();
    this.getInstitutionDetails(this.dbService.getInstitutionId());
  }
  configPanel;
  modifyConfigPanel() {
    this.configPanel = this.allConfigPanel.filter((data) => {
      if (
        data.name === 'Student' ||
        data.name === 'College Admin' ||
        data.name === 'Property' ||
        data.name === 'Help Desk'
      ) {
        return true;
      } else if (
        data.name === 'Events' &&
        this.institutionDetails.eventConfigurationNeeded
      )
        return true;
      else if (
        data.name === 'Leave Management' &&
        this.institutionDetails.leaveManagementConfigurationNeeded
      )
        return true;
      else if (
        data.name === 'Food Menu' &&
        this.institutionDetails.foodMenuConfigurationNeeded
      )
        return true;
      else if (
        data.name === 'Late Pass' &&
        this.institutionDetails.latePassConfigurationNeeded
      )
        return true;
      else if (
        data.name === 'Operations Team' &&
        this.institutionDetails.operationsTeamConfigurationNeeded
      )
        return true;
      else if (
        data.name === 'Attendance' &&
        this.institutionDetails.attendanceConfigurationNeeded
      ) {
        data.list = data.list.filter((element: any) => {
          if (
            element.name === 'Regular Attendance' &&
            this.institutionDetails.studentAttendanceConfigurationNeeded
          )
            return true;
          else if (
            element.name === 'Food Attendance' &&
            this.institutionDetails.foodAttendanceConfigurationNeeded
          )
            return true;
          else if (
            element.name === 'Late Night Attendance' &&
            this.institutionDetails.lateNightAttendanceConfigurationNeeded
          )
            return true;
          return false;
        });
        if (data.list.length > 0) return true;
        return false;
      }
      console.log('CONFIG PANEL : ', this.configPanel);
      return false;
    });
  }
  institutionDetails: any = {};
  getInstitutionDetails(id) {
    this.institutionService.getInstitute(id).subscribe(
      (res: any) => {
        this.institutionDetails = res.data;
        this.modifyConfigPanel();
      },
      (err) => {
        // console.log("ERROR: ",err);
      }
    );
  }

  makeModulesInActive() {
    this.configPanel.forEach((sp1, itemIndex) => {
      sp1.active = false;

      if (sp1.list.length > 0) {
        sp1.upDown = false;

        sp1.list.forEach((sp2) => {
          sp2.active = false;
        })
      }
    })
  }

  onFirstLevelList(panelIndex) {
    let index = 0;

    for (let firstlevelItem of this.configPanel) {
      if (panelIndex === index) {
        if (firstlevelItem.list.length > 0) {
          firstlevelItem.upDown = !firstlevelItem.upDown;
        } else {
          this.makeModulesInActive();

          firstlevelItem.active = true;

          if (firstlevelItem.type && firstlevelItem.queryPath) {
            this.router.navigate([
              `/erp/erp-isthara/${firstlevelItem.value}/${firstlevelItem.urlCheck}/list-${firstlevelItem.urlCheck}/${firstlevelItem.type}`,
            ], { queryParams: firstlevelItem.queryPath });
          } else if (firstlevelItem.queryPath) {
            this.router.navigate([`/erp/erp-isthara/${firstlevelItem.value}/${firstlevelItem.urlCheck}/list-${firstlevelItem.urlCheck}`], { queryParams: firstlevelItem.queryPath });
          } else {
            this.router.navigate([
              `/erp/erp-isthara/${firstlevelItem.value}/${firstlevelItem.urlCheck}/list-${firstlevelItem.urlCheck}`,
            ]);
          }

        }
        break;
      }
      index++;
    }
  }

  onSecondLevelList(firstLevelItem, secondLevelItem, panelIndex) {
    if (secondLevelItem.list.length > 0) {
      secondLevelItem.upDown = !secondLevelItem.upDown;
    } else {
      this.makeModulesInActive();

      firstLevelItem.upDown = true;
      firstLevelItem.active = true;
      secondLevelItem.active = true;

      if (secondLevelItem.queryPath) {
        this.router.navigate(
          [
            `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`,
          ],
          {
            queryParams: { subPath: secondLevelItem.value, main: firstLevelItem.urlCheck },
          }
        );
      } else {
        this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${secondLevelItem.value}`]);
      }
    }
  }

  onIconsSelected(panelIndex) {
    let index = 0;

    for (let firstLevelItem of this.configPanel) {
      if (panelIndex === index) {
        if (firstLevelItem.list.length > 0) {
          firstLevelItem.upDown = !firstLevelItem.upDown;

          this.makeModulesInActive();

          firstLevelItem.upDown = true;
          firstLevelItem.active = true;
          firstLevelItem.list[0].active = true;

          if (firstLevelItem.list[0].queryPath) {
            this.router.navigate(
              [
                `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`,
              ],
              {
                queryParams: { subPath: firstLevelItem.list[0].value, main: firstLevelItem.urlCheck },
              }
            );
          } else {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.list[0].value}`]);
          }
        } else {
          this.makeModulesInActive();

          firstLevelItem.active = true;

          if (firstLevelItem.type && firstLevelItem.queryPath) {
            this.router.navigate([
              `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}/${firstLevelItem.type}`,
            ], { queryParams: firstLevelItem.queryPath });
          } else if (firstLevelItem.queryPath) {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`], { queryParams: firstLevelItem.queryPath });
          } else {
            this.router.navigate([
              `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`,
            ]);
          }
        }
        break;
      }
      index++;
    }
  }

  logoclick() {
    // this.router.navigate(['/erp/erp-isthara/enquiry'],{queryParams:{subPath:"allenquiry",main:'Tenant Leads',config:false}});
  }
  goBack() {
    this.studentLivingService.setInstitutionStatus(null);
    this.router.navigate([
      '/erp/erp-isthara/student-living/list-institutions',
    ]);
  }
  showInstitutionDetails() {
    // console.log("SUTDDS:KFJL :    ", this.institutionDetails);

    let callModel = this.studentLivingService.openMessageDialog(
      SiteInfoPopupComponent,
      'auto',
      'auto',
      this.institutionDetails,
      '97vw'
    );
  }
}
