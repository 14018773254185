<section id="side-panel">
    <div class="side-panel-box">
      <div class="logo" *ngIf="srcWidth > 476">
        <img
          src="./../../../../../../assets/images/food-court/istharafoodcourt.svg"
          loading="lazy"
          alt="logo"
          class="logo"
          *ngIf="expantion === true"
          (click)="logoclick()"
        />
        <img
          src="./../../../../../../assets/images/food-court/istharafoodcourt.svg"
          loading="lazy"
          alt="logo"
          width="100%"
          class="not-expanded-logo"
          *ngIf="expantion === false"
          (click)="logoclick()"
        />
      </div>
      <!-- <div class="college-title"
        *ngIf="expantion === true">{{institutionDetails?.instituteName | titlecase}}
      <img src="./../../../../../../assets/images/side-panel/info-icon.svg" alt="info-icon" class="info-icon"></div> -->
      <div
        class="side-section-panel"
        [ngClass]="expantion === true ? 'side-section-panel' : 'not-expanded'"
      >
        <div (click)="goBack()" [ngClass]="expantion === true ? 'all-home' : 'expantion-true'">
          <img src="./../../../../../assets/images/side-panel/left-arrow.svg" alt=""> 
          &nbsp;<span class="go-home" *ngIf="expantion === true"> Go to Home</span></div>
        <ng-container *ngFor="let item of configPanel; let i = index">
          <ul
            class="icon-bar"
            (click)="listDisplay(i)"
            [ngClass]="expantion === false ? 'dropdown' : 'icon-bar'"
            *ngIf="expantion == true"
          >
            <li class="img-side-panel">
              <img
                *ngIf="rotate != i"
                src="./../../../../../assets/images/side-panel/{{
                  item.iconName
                }}.svg"
                alt="dashboard"
                loading="lazy"
                width="100%"
              />
              <img
                *ngIf="rotate == i"
                src="./../../../../../assets/images/side-panel/{{
                  item.iconName
                }}_active.svg"
                alt="dashboard"
                loading="lazy"
                width="100%"
              />
            </li>
            <li class="name-arrow">
              <div class="logo-text" (click)="showHide(item.upDown, i)">
                <span
                  [ngClass]="item.upDown == true ? 'dark' : 'default-text'"
                  class="item-name"
                  >{{ item.name }}</span
                >
                <span *ngIf="item.list?.length > 0" class="item-arrow"
                  ><img
                    src="./../../../../../assets/images/side-panel/arrow-right.svg"
                    alt="arrow"
                    loading="lazy"
                    width="7px"
                    class="arrow"
                    [ngClass]="
                      item.upDown == true ? 'normal-arrow' : 'rotated-arrow'
                    "
                  />
                </span>
              </div>
              <div
                *ngIf="item.list?.length > 0"
                class="dropdown-container"
                [ngClass]="
                  item.upDown == true ? 'display-dropdown' : 'hide-dropdown'
                "
              >
                <ng-container *ngFor="let listValue of item.list; let j = index">
                  <li
                    class="default-text"
                    (click)="routePath(item, listValue, j)"
                    [ngClass]="sublistValue?.active ? 'dark' : 'sub-default-text'"
                  >
                    <div class="logo-text sublogo-text">
                      <span
                        [ngClass]="
                          listValue.active === true ? 'dark' : 'default-text'
                        "
                      >
                        &#x2022; &nbsp; {{ listValue.name }}</span
                      >
                    </div>
                  </li>
                </ng-container>
              </div>
            </li>
          </ul>
          <ul
            class="icon-bar"
            *ngIf="expantion === false"
            [ngClass]="expantion === false ? 'dropdown' : 'icon-bar'"
          >
            <img
              *ngIf="rotate != i"
              src="./../../../../../assets/images/side-panel/{{ item.iconName }}.svg"
              alt="dashboard"
              loading="lazy"
              width="80%"
            />
            <img
              *ngIf="rotate == i"
              src="./../../../../../assets/images/side-panel/{{
                item.iconName
              }}_active.svg"
              alt="dashboard"
              loading="lazy"
              width="80%"
            />
          </ul>
        </ng-container>
      </div>
    </div>
  </section>