import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupDbService } from './popup-db.service';

@Injectable({
  providedIn: 'root',
})
export class SigninSignupServicesService {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _dbStorage: PopupDbService
  ) {}

  //snack bar
  openSnackBarMessage(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5 * 1000,
    });
  }

  //signUp
  signUp(signUpdata) {
    return this.http.post(
      environment.baseUrl + '/user/userdetails/ist/signup',
      signUpdata
    );
  }
  //signin
  signIn(signIndata) {
    return this.http.post(
      environment.baseUrl + '/user/userdetails/ist/login',
      signIndata
    );
  }
  //verifyotp
  verifyNow(verifyOtp) {
    return this.http.post(
      environment.baseUrl + '/user/userdetails/ist/validate',
      verifyOtp
    );
  }
  //Resend OTP
  resendOtp(resendotp) {
    return this.http.post(
      environment.baseUrl + '/user/userdetails/ist/resendotp',
      resendotp
    );
  }

  //logout
  logout(data, userId) {
    return this.http.put(
      environment.baseUrl + `/user/user/${userId}/logout`,
      data
    );
  }

  stafflogin(data) {
    return this.http.post(environment.baseUrl + `/user/staff/ist/login`, data);
  }

  staffByid(staffId) {
    return this.http.get(
      environment.baseUrl + `/user/staff/ist?allDetails=true&staffId=${staffId}`
    );
  }
  changePassword(data) {
    const token = this._dbStorage.getToken();
    var headers_object = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + token
    );
    return this.http.put(environment.baseUrl + `/user/staff/password`, data, {
      headers: headers_object,
    });
  }
  getInstitute(id) {
    return this.http.get(
      // environment.baseUrl+`/student/student/ist/institute/get/${id}`
      environment.baseUrl + `/student/institution/ist/v1/get/${id}`
    );
  }
}
