import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit {
  constructor(
    private router: Router,
  ) { }
  ngOnInit() {

    let url = this.router.url.split('/')[3].split('?')[0];
    console.log(url);

    if ('configuration' === url) {
      this.configuration = 'configuration';
    } else if ('institution-details' === url) {
      this.configuration = 'studentLiving';
    } else if ('institution-foodcourt' === url) {
      this.configuration = 'institution-foodcourt';
    } else if ('institution-configuration' === url) {
      this.configuration = 'institution-configuration';
    } else {
      this.configuration = 'main';
    }


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.url.split('/')[3].split('?')[0];
        console.log(url);


        if ('configuration' === url) {
          this.configuration = 'configuration';
        } else if ('institution-details' === url) {
          this.configuration = 'studentLiving';
        } else if ('institution-foodcourt' === url) {
          this.configuration = 'institution-foodcourt';
        } else if ('institution-configuration' === url) {
          this.configuration = 'institution-configuration';
        } else {
          this.configuration = 'main';
        }
      }
    });

  }

  @Input() expantion: boolean;
  @Input() srcWidth;
  @Input() panelName: string;
  configuration: string = '';

  @Output('closePanel') closePanel = new EventEmitter();

  closeSidePanel() {
    this.closePanel.emit();
  }
}
