<form [formGroup]="signin">
    <div class="popup-heading">UPDATE PASSWORD</div>
    <img
        class="close-btn"
        (click)="onNoClick()"
        src="./../../../../../../../assets/images/common/close.svg"
        alt="close"
    />
    <div class="single-field">
    <div class="feild-heading">Old Password</div>
    <div class="fields">
        <mat-form-field class="sfull-width" appearance="outline">
            <input matInput placeholder="Old Password" [type]="hide ? 'password' : 'text'" formControlName="password" required [(ngModel)]="oldpassword">
            <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
        </mat-form-field>
        </div>
    </div>
    <div class="single-field">
    <div class="feild-heading">New Password</div>
    <div class="fields">
        <mat-form-field class="sfull-width" appearance="outline">
            <input matInput placeholder="New Password" [type]="passwordshow ? 'password' : 'text'" formControlName="new_password" required [(ngModel)]="newpassword">
            <mat-icon matSuffix (click)="passwordshow = !passwordshow" class="eye-icon">{{passwordshow ? 'visibility_off' : 'visibility'}}</mat-icon>
            <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
        </mat-form-field>
        </div>
    </div>
    <div class="single-field">
    <div class="feild-heading">Confirm New Password</div>
    <div class="fields">
        <mat-form-field class="sfull-width" appearance="outline">
            <input matInput placeholder="Password" [type]="conformpasswordShow ? 'password' : 'text'" 
            formControlName="confirm_password" required [(ngModel)]="confirmnewpassword">
            <mat-icon matSuffix (click)="conformpasswordShow = !conformpasswordShow" class="eye-icon">{{conformpasswordShow ? 'visibility_off' : 'visibility'}}</mat-icon>
            <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
        </mat-form-field>
        </div>
    </div>

    <div [ngClass]="oldpassword != '' && newpassword != '' && confirmnewpassword != '' ? 'reset-password' : 'disabled-btn'">
        <button (click)="resetpassword()" *ngIf="!loader ; else loading"
        [disabled]="(oldpassword ==='' || oldpassword === null)&&(newpassword === '' || newpassword === null)&&
        (confirmnewpassword === '' || confirmnewpassword === null)">
            Change Password
        </button>
        <ng-template #loading>
            <button>
                <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
              </button>
        </ng-template>
    </div>
</form>