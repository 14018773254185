// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: 'https://dev-api.isthara.com',
  fcBaseUrl: 'https://dev-isthara.web.app/codining/fc/',
  serverIP: 'https://dev-foodcourt.isthara.com',
  websiteBaseUrl: 'https://dev-isthara.web.app',
  prodUrl: 'https://dev-report.isthara.com',

  
  sasToken:
    '?sp=racwli&st=2023-07-06T05:50:23Z&se=2030-07-06T13:50:23Z&sv=2022-11-02&sr=c&sig=xzzIxmfnGiKYdMaiacsfo%2Bps%2Bfc4R94U6BrzRNAUeFw%3D',
  accountName: 'istharadev',
  containerName: 'dev-isthara',

  // localIp: 'http://localhost:8765',
  testUrl: '/ist', //testing purpose
  googleMapsKey: 'AIzaSyDjOcdDo78v7TuPUrmdX-UxAUjOx5J7KQU',
  imageUploadSize: 1000000,
  image: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'us-east-1:39c0b6a2-7c1e-4452-ab8c-c3e9a79aeafe',
          Region: 'us-east-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'dev-isthara',
        Region: 'us-east-1',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
