import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupDbService } from './../../../authentication/services/popup-db.service';

@Component({
  selector: 'app-main-panel',
  templateUrl: './main-panel.component.html',
  styleUrls: ['./main-panel.component.scss'],
})
export class MainPanelComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dbService: PopupDbService
  ) {}

  rotate: number = 0;
  configuration = '';

  @Input() expantion: boolean;
  @Input() srcWidth;

  sidepanel = [
    {
      active: false,
      name: 'Tenant Leads',
      value: 'enquiry',
      id: 'enquiry',
      upDown: false,
      allParams: true,
      list: [
        {
          name: 'Dashboard',
          value: 'enq-dashboard',
          id: 'enq-dashboard',
          active: false,
          type: '',
          allParams: false,
          queryPath: null,
          list: [],
        },
        {
          name: 'My Enquiry',
          value: 'myenquiry',
          id: 'myenquiry',
          active: false,
          type: '',
          allParams: true,
          queryPath: {
            subPath: 'myenquiry',
            main: 'Tenant Leads',
            config: 'main',
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'All Enquiry',
          value: 'allenquiry',
          id: 'allenquiry',
          allParams: true,
          type: '',
          active: false,
          queryPath: {
            subPath: 'allenquiry',
            main: 'Tenant Leads',
            config: 'main',
            pageno: 1,
          },
          list: [],
        },
        // {
        //   name: 'Old Enquiry',
        //   value: 'oldenquiry',
        //   active: false,
        //   queryPath: true,
        // },
      ],
    },
    // {
    //   name: 'Bookings',
    //   value: 'booking',
    //   upDown: false,
    //   allParams: true,
    //   list: [
    //     {
    //       name: 'Dashboard',
    //       value: 'booking-dashboard',
    //       active: false,
    //       queryPath: false,
    //       pagination: true,
    //     },
    //     {
    //       name: 'All Bookings',
    //       value: 'allbookings',
    //       active: false,
    //       queryPath: true,
    //     },
    //     {
    //       name: 'Completed',
    //       value: 'completed',
    //       active: false,
    //       queryPath: true,
    //     },
    //     {
    //       name: 'Booking Cancelled',
    //       value: 'bookingcancelled',
    //       active: false,
    //       queryPath: true,
    //     },
    //   ],
    // },
    {
      active: false,
      name: 'Property Inventory',
      value: 'creation',
      id: 'property-inventory',
      upDown: false,
      allParams: false,
      list: [
        // {
        //   name: 'Dashboard',
        //   value: 'property-dashboard',
        //   id: 'property-inventory-dashboard',
        //   active: false,
        //   type: '',
        //   allParams: false,
        //   queryPath: null,
        //   list: [],
        // },
        {
          name: 'Properties',
          value: 'list-property',
          id: 'list-property-inventory',
          active: false,
          type: '',
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
      ],
    },
    {
      active: false,
      name: 'Student Living',
      value: 'student-living',
      id: 'student-living',
      upDown: false,
      allParams: false,
      list: [
        // {
        //   name: 'Dashboard',
        //   value: 'cre-dashboard',
        //   active: false,
        //   queryPath: null,
        // },
        {
          name: 'Institutions',
          value: 'list-institutions',
          id: 'list-institutions',
          active: false,
          type: '',
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Off Campus Properties',
          value: 'list-sliving-property',
          id: 'list-sliving-property',
          type: 'off-campus',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
      ],
    },
    {
      active: false,
      name: 'Staff',
      value: 'staff',
      id: 'staff',
      upDown: false,
      allParams: false,
      queryPath: {
        pageno: 1,
      },
      list: [],
    },
    {
      active: false,
      name: 'Food Court',
      value: 'food-court',
      id: 'food-court',
      upDown: false,
      allParams: false,
      list: [
        // {
        //   name: 'Dashboard',
        //   value: 'foodcourts-dashboard',
        //   active: false,
        //   queryPath: false,
        // },
        {
          name: 'Sites',
          value: 'list-sites',
          id: 'list-sites',
          active: false,
          type: '',
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Foodcourts',
          value: 'list-foodcourts',
          id: 'list-foodcourts',
          active: false,
          type: '',
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Third Parties',
          value: 'third-party',
          id: 'third-party',
          active: false,
          type: '',
          allParams: false,
          list: [
            {
              name: 'Pet Pooja',
              value: 'pet-pooja',
              id: 'pet-pooja',
              active: false,
              allParams: false,
              list: [
                {
                  name: 'Items',
                  value: 'items',
                  id: 'items',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
              ],
            },
            {
              name: 'Urban Piper',
              value: 'urban-piper',
              id: 'urban-piper',
              active: false,
              allParams: false,
              list: [
                {
                  name: 'Urban Piper Items',
                  value: 'items',
                  id: 'items',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
              ],
            },
            // {
            //   name: 'ONDC',
            //   value: 'ondc',
            //   id: 'ondc',
            //   active: false,
            //   allParams: false,
            //   list: [
            //     {
            //       name: 'ondc vendors',
            //       value: 'ondc-vendors',
            //       id: 'ondc-vendors',
            //       active: false,
            //       allParams: false,
            //       // queryPath: {
            //       //   pageno: 1,
            //       // },
            //       list: [],
            //     },
            //     {
            //       name: 'Ondc Items',
            //       value: 'ondc-items',
            //       id: 'ondc-items',
            //       active: false,
            //       allParams: false,
            //       // queryPath: {
            //       //   pageno: 1,
            //       // },
            //       list: [],
            //     },

            //   ],
            // },
            {
              name: 'Third Party User',
              value: 'per-sq-ft',
              id: 'per-sq-ft',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
          ],
        },
        {
          name: 'Sales Estimations',
          value: 'sales-estimation',
          id: 'sales-estimation',
          active: false,
          type: '',
          allParams: false,
          list: [
            {
              name: 'Foodcourt Wise',
              value: 'foodcourt-sales-estimation',
              id: 'foodcourt-sales-estimation',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
            {
              name: 'Vendor Wise',
              value: 'vendor-sales-estimation',
              id: 'vendor-sales-estimation',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
          ],
        },
        {
          name: 'Reports',
          value: 'foodcourts-reports',
          id: 'foodcourts-reports',
          active: false,
          upDown: false,
          type: '',
          allParams: false,
          list: [
            {
              name: 'Sales Summary',
              value: 'sales-summary',
              id: 'sales-summary',
              active: false,
              allParams: false,
              queryPath: null,
              list: [],
            },
            {
              name: 'Item Summary',
              value: 'item-summary',
              id: 'item-summary',
              active: false,
              allParams: false,
              queryPath: null,
              list: [],
            },
            {
              name: 'Invoice Summary',
              value: 'invoice-summary',
              id: 'invoice-summary',
              active: false,
              allParams: false,
              queryPath: null,
              list: [],
            },
            {
              name: 'Vendor Summary',
              value: 'vendor-summary',
              id: 'vendor-summary',
              active: false,
              allParams: false,
              queryPath: null,
              list: [],
            },
            // {
            //   name: 'Credit Sales Summary',
            //   value: 'credit-sales-summary',
            //   id: 'credit-sales-summary',
            //   active: false,
            //   allParams: false,
            //   queryPath: null,
            //   list: []
            // },
            {
              name: 'Markup Sales Summary',
              value: 'markup-sales-summary',
              id: 'markup-sales-summary',
              active: false,
              allParams: false,
              queryPath: null,
              list: [],
            },
            {
              name: 'Tips Summary',
              value: 'tips-summary',
              id: 'tips-summary',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
            {
              name: 'Excel Reports',
              value: 'excel-report',
              active: false,
              allParams: false,
              queryPath: null,
              list: [],
            },
          ],
        },
        {
          name: 'Quick Access',
          value: 'quick-access',
          id: 'quick-access',
          active: false,
          upDown: false,
          type: '',
          allParams: false,
          list: [
            {
              name: 'Cam Charges',
              value: 'cam-charges',
              id: 'cam-charges',
              active: false,
              upDown: false,
              allParams: false,
              list: [
                {
                  name: 'Estimated Charges',
                  value: 'list-estimated-cam',
                  id: 'list-estimated-cam',
                  active: false,
                  allParams: false,
                  queryPath: {
                    mode: 'global',
                    pageno: 1,
                  },
                  list: [],
                },
                {
                  name: 'Actual Charges',
                  value: 'list-actual-cam',
                  id: 'list-actual-cam',
                  active: false,
                  allParams: false,
                  queryPath: {
                    mode: 'global',
                    pageno: 1,
                  },
                  list: [],
                },
              ],
            },
            {
              name: 'POS Offline Sales',
              value: 'list-pos-data',
              id: 'list-pos-data',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
            // {
            //   name: 'Refund Points',
            //   value: 'list-refund-points',
            //   id: 'list-refund-points',
            //   active: false,
            //   allParams: false,
            //   queryPath: {
            //     pageno: 1
            //   },
            //   list: []
            // },
            {
              name: 'Bulk orders',
              value: 'list-bulk-orders',
              id: 'list-bulk-orders',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
            {
              name: 'Rating & Review',
              value: 'list-rating-review',
              id: 'list-rating-review',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
            {
              name: 'Operations Team',
              value: 'list-operation-team',
              id: 'list-operation-team',
              active: false,
              allParams: false,
              queryPath: null,
              list: [
                {
                  name: 'Approved Staff',
                  value: 'approved-staff',
                  id: 'list-approved-staff',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
                {
                  name: 'Pending Staff',
                  value: 'pending-staff',
                  id: 'list-pending-staff',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
              ],
            },
            {
              name: 'QR Code',
              value: 'list-qr',
              id: 'list-qr',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
            {
              name: 'Terminal',
              value: 'list-terminal',
              id: 'list-terminal',
              active: false,
              allparams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
            {
              name: 'Partner Admin',
              value: 'partner-admin',
              id: 'partner-admin',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
            {
              name: 'Untriggered Order',
              value: 'untriggered-order',
              id: 'partner-admin',
              active: false,
              allParams: false,
              queryPath: {
                pageNo: 1,
              },
              list: [],
            },
            {
              name: 'Pull DB',
              value: 'pull-dp',
              id: 'partner-admin',
              active: false,
              allParams: false,
              queryPath: {
                pageNo: 1,
              },
              list: [],
            },
            {
              name: 'Day End Sales',
              value: 'list-day-end-sales',
              id: 'partner-admin',
              active: false,
              allParams: false,
              queryPath: {
                pageNo: 1,
              },
              list: [],
            },
            {
              name: 'POS Status',
              value: 'list-live-pos',
              id: 'partner-admin',
              active: false,
              allParams: false,
              queryPath: {
                pageNo: 1,
              },
              list: [],
            },
            {
              name: 'EDC Device',
              value: 'list-edc-mapping',
              id: 'partner-admin',
              active: false,
              allParams: false,
              queryPath: {
                pageNo: 1,
              },
              list: [],
            },
          ],
        },
        {
          name: 'Food Subscription',
          value: 'food-subscription',
          id: 'food-subscription',
          active: false,
          type: '',
          upDown: false,
          allParams: false,
          list: [
            {
              name: 'Property',
              value: 'property',
              id: 'prop-subscription-main',
              active: false,
              allParams: false,
              queryPath: null,
              list: [
                {
                  name: 'DashBoard',
                  value: 'dashboard',
                  id: 'prop-subscription-dashboard',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
                {
                  name: 'Food Subscription Plans',
                  value: 'list-foodSubscription-plans',
                  id: 'list-prop-foodSubscription-plans',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
                {
                  name: 'Users Food Subscriptions',
                  value: 'list-users-foodSubscriptions',
                  id: 'list-prop-users-foodSubscriptions',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
                {
                  name: 'Property Subscriptions',
                  value: 'property-subscription',
                  id: 'property-subscription',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
              ],
            },
            {
              name: 'Foodcourt',
              value: 'foodcourt',
              id: 'foodcourt-subscription-main',
              active: false,
              allParams: false,
              queryPath: null,
              list: [
                {
                  name: 'Dashboard',
                  value: 'foodcourt-dashboard',
                  id: 'foodcourt-subscription-dashboard',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
                {
                  name: 'Food Subscription Plans',
                  value: 'list-foodSubscription-plans',
                  id: 'list-fc-Subscription-plans',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
                {
                  name: 'Users Food Subscriptions',
                  value: 'list-users-foodSubscriptions',
                  id: 'list-fc-users-foodSubscriptions',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },

                {
                  name: 'Vendor Dashboard',
                  value: 'vendor-dashboard',
                  id: 'fc-vendor-subscription-dashboard',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
              ],
            },
          ],
        },
        {
          name: 'Single Point of Contact',
          value: 'list-spc',
          id: 'list-spc',
          active: false,
          type: '',
          allParams: null,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Discounts',
          value: 'discounts/dashboard',
          id: 'discounts-dashboard',
          active: false,
          type: '',
          allParams: null,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
      ],
    },
    {
      active: false,
      name: 'Counters',
      value: 'counters',
      id: 'counters',
      type: '',
      upDown: false,
      allParams: false,
      list: [
        {
          name: 'Dashboard',
          value: 'counters-dashboard',
          id: 'counters-dashboard',
          active: false,
          allParams: false,
          queryPath: null,
          list: [],
        },
        {
          name: 'Counters',
          value: 'list-counters',
          id: 'list-counters',
          active: false,
          allParams: false,
          queryPath: null,
          list: [],
        },
      ],
    },
    // {
    //   active: false,
    //   name: 'Vendors',
    //   value: 'vendors',
    //   id: 'vendors',
    //   upDown: false,
    //   allParams: false,
    //   queryPath: {
    //     pageno: 1
    //   },
    //   list: [],
    // },
    {
      active: false,
      name: 'Vendor',
      value: 'vendor',
      id: 'vendor',
      type: '',
      upDown: false,
      allParams: false,
      list: [
        {
          name: 'Vendors',
          value: 'list-vendors',
          id: 'list-vendors',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Security Deposits',
          value: 'list-security-deposits',
          id: 'list-security-deposits',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
      ],
    },
    {
      active: false,
      name: 'Corporate Connect',
      value: 'corporate-connect',
      id: 'corporate-connect',
      type: '',
      upDown: false,
      allParams: false,
      list: [
        {
          name: 'Employer',
          value: 'list-employers',
          id: 'list-employers',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Employer Admin',
          value: 'list-employer-admin',
          id: 'list-employer-admin',
          active: false,
          allParams: false,
          queryPath: null,
          list: [],
        },
        {
          name: 'Employees',
          value: 'list-employees',
          id: 'list-employees',
          active: false,
          allParams: false,
          queryPath: null,
          list: [],
        },
        {
          name: 'Invoices',
          value: 'list-invoices',
          id: 'list-invoices',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Payment Records',
          value: 'list-payment-records',
          id: 'list-payment-records',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
      ],
    },
    {
      active: false,
      name: 'Communication',
      value: 'communication',
      id: 'communication',
      type: '',
      upDown: false,
      allParams: false,
      list: [
        {
          name: 'Foodcourt',
          value: 'list-foodcourt',
          id: 'fc-communication-list',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [
            //   {
            //   name: 'Dashboard',
            //   value: 'notification-dashboard',
            //   active: false,
            //   allParams: false,
            //   queryPath: {
            //     mode: 'global',
            //     pageno: 1
            //   },
            //   list: [],
            // },
            {
              name: 'Push Notification',
              value: 'notification-listing',
              id: 'notification-listing',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              list: [],
            },
          ],
        },
      ],
    },
    // {
    //   name: 'Food-Menu',
    //   value: 'food-menu',
    //   upDown: false,
    //   allParams: false,
    //   list: [],
    // },
    // {
    //   name: 'Events',
    //   value: 'events',
    //   upDown: false,
    //   allParams: false,
    //   list: [],
    // },
    {
      active: false,
      name: 'Foodcourt Owner',
      value: 'foodcourt-owner',
      id: 'foodcourt-owner',
      upDown: false,
      allParams: false,
      list: [
        {
          name: 'Owner',
          value: 'list-owners',
          id: 'foodcourt-owners-list',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Earnings',
          value: 'earnings',
          id: 'foodcourt-owner-earnings',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Revenue Report',
          value: 'list-reports',
          id: 'foodcourt-owner-reports',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
        {
          name: 'Foodcourt Commercial',
          value: 'fc-commercial-report',
          id: 'fc-commercial-report',
          active: false,
          allParams: false,
          queryPath: null,
          list: [],
        },
      ],
    },
    {
      active: false,
      name: 'Finance',
      value: 'finance',
      id: 'finance',
      upDown: false,
      allParams: false,
      list: [
        // {
        //   name: 'Foodcourt',
        //   value: 'foodcourt',
        //   active: false,
        //   queryPath: false,
        // },
        {
          name: 'Food Court',
          value: 'food-court',
          id: 'foodcourt-finance',
          active: false,
          type: '',
          upDown: false,
          allParams: false,
          list: [
            // {
            //   name: 'Dashboard',
            //   value: 'dashboard',
            //   active: true,
            //   queryPath: false,
            //   subPath:'finance'
            // },
            // {
            //   name: 'SOA Vendor',
            //   value: 'soa-vendor',
            //   active: false,
            //   queryPath: true,
            //   subPath:'finance'
            // },
            {
              name: 'Settlements',
              value: 'settlements',
              id: 'settlements',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              subPath: 'finance',
              list: [],
            },
            {
              name: 'Vendor Settlements',
              value: 'vendor-settlements',
              id: 'vendor-settlements',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              subPath: 'finance',
              list: [],
            },
            {
              name: 'Platform Charges',
              value: 'platform-charges',
              id: 'platform-charges',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              subPath: 'finance',
              list: [],
            },
            {
              name: 'Payouts',
              value: 'payouts-listing',
              id: 'payouts-listing',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              subPath: 'finance',
              list: [],
            },
            {
              name: 'Advance',
              value: 'advances-listing',
              id: 'advances-listing',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              subPath: 'finance',
              list: [],
            },
            {
              name: 'Penalties',
              value: 'penalties',
              id: 'penalties',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              subPath: 'finance',
              list: [],
            },
            {
              name: 'Discounts',
              value: 'discounts',
              id: 'discounts',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              subPath: 'finance',
              list: [],
            },
            {
              name: 'Statement Of Account',
              value: 'statement-of-account',
              id: 'statement-of-account',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              subPath: 'finance',
              list: [],
            },
            {
              name: 'Cash Deposit',
              value: 'cash-deposit',
              id: 'cash-deposit',
              active: false,
              allParams: false,
              upDown: false,
              list: [
                {
                  name: 'Dashboard',
                  value: 'cash-deposit-dashboard',
                  id: 'cash-deposit-dashboard',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
                {
                  name: 'Deposit Request',
                  value: 'cash-deposit-request',
                  id: 'cash-deposit-request',
                  active: false,
                  allParams: false,
                  queryPath: {
                    pageno: 1,
                  },
                  list: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      active: false,
      name: 'Helpdesk',
      value: 'helpdesk',
      id: 'helpdesk',
      upDown: false,
      allParams: false,
      list: [
        // {
        //   name: 'Foodcourt',
        //   value: 'foodcourt',
        //   active: false,
        //   queryPath: false,
        // },
        {
          name: 'Food Court',
          value: 'food-court',
          id: 'foodcourt-helpdesk',
          active: false,
          type: '',
          upDown: false,
          allParams: false,
          list: [
            {
              name: 'Dashboard',
              value: 'dashboard',
              id: 'helpdesk-dashboard',
              active: false,
              allParams: false,
              queryPath: null,
              subPath: 'helpdesk',
              list: [],
            },
            {
              name: 'Tickets',
              value: 'tickets',
              id: 'helpdesk-tickets',
              active: false,
              allParams: false,
              queryPath: {
                pageno: 1,
              },
              pagination: true,
              subPath: 'helpdesk',
              list: [],
            },
          ],
        },
        {
          name: 'Refund Points',
          value: 'refund-points',
          id: 'refund-points',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
      ],
    },
    {
      active: false,
      name: 'Miscellaneous',
      value: 'miscellaneous',
      id: 'miscellaneous',
      type: '',
      upDown: false,
      allParams: false,
      list: [
        {
          name: 'Promotional Banner',
          value: 'promotional-banner',
          id: 'promotional-banner',
          active: false,
          allParams: false,
          queryPath: {
            pageno: 1,
          },
          list: [],
        },
      ],
    },
    // {
    //   name: 'Isthara Points',
    //   value: 'isthara-points',
    //   upDown: false,
    //   allParams: false,
    //   list: [
    //     {
    //       name: 'Finance Transactions',
    //       value: 'finance-transactions',
    //       active: false,
    //       queryPath: false,
    //     },
    //     {
    //       name: 'Staff Transactions',
    //       value: 'staff-transactions',
    //       active: false,
    //       queryPath: false,
    //     },
    //     {
    //       name: 'User Transactions',
    //       value: 'user-transactions',
    //       active: false,
    //       queryPath: false,
    //     },
    //   ],
    // },
    // {
    //   name: 'Food Court',
    //   value: 'foodcourt',
    //   upDown: false,
    //   allParams:false,
    // },
  ];

  subpath;
  main;
  reloading: boolean = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!this.reloading) {
        this.reloading = true;
        if (params.subPath || params.main) {
          this.subpath = params.subPath;
          this.main = params.main;
          this.pathChecking();
        } else {
          let currentUrl = window.location.href;
          let parts = currentUrl.split('/');
          let position = parts.indexOf('erp');
          parts = parts.slice(position);
          this.main = parts[2];
          this.subpath = parts[3];
          this.pathChecking();
        }
      }
    });
  }

  pathChecking() {
    this.sidepanel.forEach((panel) => {
      if (
        panel.name === this.main.split('?')[0] ||
        panel.value === this.main.split('?')[0]
      ) {
        panel.upDown = true;
        panel.active = true;
        if (panel.allParams) {
          if (panel.list.length > 0) {
            panel.list.forEach((element) => {
              if (
                element.name === this.subpath ||
                element.value === this.subpath
              ) {
                element.active = true;
              }
            });
          }
        } else {
          if (panel.list.length > 0) {
            panel.list.forEach((element) => {
              if (
                element.name.toLocaleLowerCase() === this.subpath ||
                element.value === this.subpath.split('?')[0]
              ) {
                element.active = true;
                if (element.list?.length > 0) {
                  element.upDown = true;
                  let currentUrl = window.location.href;
                  let parts = currentUrl.split('/');
                  let position = parts.indexOf('erp');
                  parts = parts.slice(position);
                  // console.log("parts jskalfjskld",parts[4])
                  element.list.forEach((sl) => {
                    if (parts[4].split('?')[0] === sl.value) {
                      sl.active = true;

                      if (sl.list && sl.list.length > 0) {
                        sl.upDown = true;

                        sl.list.forEach((ssl) => {
                          if (ssl.value === parts[5].split('?')[0]) {
                            ssl.active = true;
                          }
                        });
                      }
                    } else {
                      sl.active = false;
                    }
                  });
                }
              }
            });
          }
        }
      } else {
        panel.upDown = false;
      }
    });
  }

  makeModulesInActive() {
    this.sidepanel.forEach((sp1, itemIndex) => {
      sp1.active = false;

      if (sp1.list.length > 0) {
        sp1.upDown = false;

        sp1.list.forEach((sp2) => {
          sp2.active = false;

          if (sp2.list.length > 0) {
            sp2.upDown = false;

            sp2.list.forEach((sp3) => {
              sp3.active = false;

              if (sp3.list.length > 0) {
                sp3.upDown = false;

                sp3.list.forEach((sp4) => {
                  sp4.active = false;
                });
              }
            });
          }
        });
      }
    });
  }

  openNextLevelItem(item: any) {
    let selectedItem = item;

    while (selectedItem.list[0].list.length > 0) {
      selectedItem.list[0].upDown = !selectedItem.list[0].upDown;
      selectedItem = selectedItem.list[0];
    }

    setTimeout(() => {
      this.scrollToSelectedElement(selectedItem);
    }, 100);
  }

  scrollToSelectedElement(item) {
    const showElement = document.getElementById(item.id);

    if (showElement) {
      showElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  onFirstLevelList(panelIndex) {
    let index = 0;

    for (let item of this.sidepanel) {
      if (panelIndex === index) {
        if (item.list.length > 0) {
          item.upDown = !item.upDown;

          if (item.list[0].list.length > 0) {
            this.openNextLevelItem(item);
          } else {
            setTimeout(() => {
              this.scrollToSelectedElement(item);
            }, 100);
          }
        } else {
          this.makeModulesInActive();

          item.active = true;

          if (item.queryPath) {
            this.router.navigate([`/erp/erp-isthara/${item.value}`], {
              queryParams: item.queryPath,
            });
          } else {
            this.router.navigate([`/erp/erp-isthara/${item.value}`]);
          }

          setTimeout(() => {
            this.scrollToSelectedElement(item);
          }, 100);
        }

        break;
      }
      index++;
    }
  }

  onSecondLevelList(firstLevelItem, secondLevelItem, panelIndex) {
    if (secondLevelItem.list.length > 0) {
      secondLevelItem.upDown = !secondLevelItem.upDown;

      if (secondLevelItem.list[0].list.length > 0) {
        this.openNextLevelItem(secondLevelItem);
      } else {
        setTimeout(() => {
          this.scrollToSelectedElement(secondLevelItem);
        }, 100);
      }
    } else {
      this.makeModulesInActive();

      setTimeout(() => {
        this.scrollToSelectedElement(secondLevelItem);
      }, 100);

      firstLevelItem.upDown = true;
      firstLevelItem.active = true;
      secondLevelItem.active = true;

      if (secondLevelItem.allParams) {
        this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}`], {
          queryParams: secondLevelItem.queryPath,
        });
      } else if (secondLevelItem.type && secondLevelItem.queryPath) {
        this.router.navigate(
          [
            `/erp/erp-isthara/${firstLevelItem.value}/${secondLevelItem.value}/${secondLevelItem.type}`,
          ],
          { queryParams: secondLevelItem.queryPath }
        );
      } else if (secondLevelItem.queryPath) {
        this.router.navigate(
          [`/erp/erp-isthara/${firstLevelItem.value}/${secondLevelItem.value}`],
          {
            queryParams: secondLevelItem.queryPath,
          }
        );
      } else {
        this.router.navigate([
          `/erp/erp-isthara/${firstLevelItem.value}/${secondLevelItem.value}`,
        ]);
      }
    }
  }

  onThirdLevelList(
    firstLevelItem,
    secondLevelItem,
    thirdLevelItem,
    panelIndex
  ) {
    if (thirdLevelItem.list.length > 0) {
      thirdLevelItem.upDown = !thirdLevelItem.upDown;

      if (thirdLevelItem.list[0].list.length > 0) {
        this.openNextLevelItem(thirdLevelItem);
      } else {
        setTimeout(() => {
          this.scrollToSelectedElement(thirdLevelItem);
        }, 100);
      }
    } else {
      this.makeModulesInActive();

      setTimeout(() => {
        this.scrollToSelectedElement(thirdLevelItem);
      }, 100);

      firstLevelItem.active = true;
      firstLevelItem.upDown = true;
      secondLevelItem.active = true;
      secondLevelItem.upDown = true;
      thirdLevelItem.active = true;

      if (thirdLevelItem.queryPath) {
        this.router.navigate(
          [
            `/erp/erp-isthara/${firstLevelItem.value}/${secondLevelItem.value}/${thirdLevelItem.value}`,
          ],
          { queryParams: thirdLevelItem.queryPath }
        );
      } else {
        this.router.navigate([
          `/erp/erp-isthara/${firstLevelItem.value}/${secondLevelItem.value}/${thirdLevelItem.value}`,
        ]);
      }
    }
  }

  onFourthLevelList(
    firstLevelItem,
    secondLevelItem,
    thirdLevelItem,
    fourthLevelItem,
    panelIndex
  ) {
    if (fourthLevelItem.list.length > 0) {
      fourthLevelItem.upDown = !fourthLevelItem.upDown;

      if (fourthLevelItem.list[0].list.length > 0) {
        this.openNextLevelItem(fourthLevelItem);
      } else {
        setTimeout(() => {
          this.scrollToSelectedElement(fourthLevelItem);
        }, 100);
      }
    } else {
      this.makeModulesInActive();

      setTimeout(() => {
        this.scrollToSelectedElement(fourthLevelItem);
      }, 100);

      firstLevelItem.active = true;
      firstLevelItem.upDown = true;
      secondLevelItem.active = true;
      secondLevelItem.upDown = true;
      thirdLevelItem.active = true;
      thirdLevelItem.upDown = true;
      fourthLevelItem.active = true;

      if (fourthLevelItem.queryPath) {
        this.router.navigate(
          [
            `/erp/erp-isthara/${firstLevelItem.value}/${secondLevelItem.value}/${thirdLevelItem.value}/${fourthLevelItem.value}`,
          ],
          { queryParams: fourthLevelItem.queryPath }
        );
      } else {
        this.router.navigate([
          `/erp/erp-isthara/${firstLevelItem.value}/${secondLevelItem.value}/${thirdLevelItem.value}/${fourthLevelItem.value}`,
        ]);
      }
    }
  }

  onIconsSelected(panelIndex) {
    let index = 0;

    for (let firstLevelItem of this.sidepanel) {
      if (panelIndex === index) {
        if (firstLevelItem.list.length > 0) {
          firstLevelItem.upDown = !firstLevelItem.upDown;

          this.makeModulesInActive();

          firstLevelItem.upDown = true;
          firstLevelItem.active = true;
          firstLevelItem.list[0].active = true;

          if (firstLevelItem.list[0].list.length > 0) {
            firstLevelItem.list[0].upDown = true;
            firstLevelItem.list[0].list[0].active = true;
          }

          if (firstLevelItem.list[0].allParams) {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}`], {
              queryParams: firstLevelItem.list[0].queryPath,
            });
          } else if (
            firstLevelItem.list[0].type &&
            firstLevelItem.list[0].queryPath
          ) {
            this.router.navigate(
              [
                `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.list[0].value}/${firstLevelItem.list[0].type}`,
              ],
              { queryParams: firstLevelItem.list[0].queryPath }
            );
          } else if (firstLevelItem.list[0].queryPath) {
            this.router.navigate(
              [
                `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.list[0].value}`,
              ],
              {
                queryParams: firstLevelItem.list[0].queryPath,
              }
            );
          } else {
            this.router.navigate([
              `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.list[0].value}`,
            ]);
          }
        } else {
          this.makeModulesInActive();

          firstLevelItem.active = true;

          if (firstLevelItem.queryPath) {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}`], {
              queryParams: firstLevelItem.queryPath,
            });
          } else {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}`]);
          }
        }
        break;
      }
      index++;
    }
  }

  logoclick() {
    // this.router.navigate(['/erp/erp-isthara/enquiry'],{queryParams:{subPath:"allenquiry",main:'Tenant Leads',config:false}});
  }
  goBack() {
    this.router.navigate(['/erp/erp-isthara/enquiry'], {
      queryParams: {
        subPath: 'allenquiry',
        main: 'Tenant Leads',
        config: false,
        pageno: 1,
      },
    });
  }
}
