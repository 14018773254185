import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupDbService } from '../../authentication/services/popup-db.service';
import { environment } from './../../../../../environments/environment';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

export enum CurrentMode {
  'GLOBAL_LEVEL' = 'GLOBAL_LEVEL',
  'SITE_LEVEL' = 'SITE_LEVEL',
}
@Injectable({
  providedIn: 'root',
})
export class FoodCourtService {
  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    public _bottomSheet: MatBottomSheet,
    private _db: PopupDbService
  ) {}

  private pageNoSnapshot: number = 0;
  private sizeSnapshot: number = 10;
  private isInsideEdit: boolean = false;

  getPageNo() {
    return this.pageNoSnapshot;
  }
  setPageNo(pageNo) {
    this.pageNoSnapshot = pageNo;
  }

  getSizeSanpshot() {
    return this.sizeSnapshot;
  }
  setSizeSnapshot(size) {
    this.sizeSnapshot = size;
  }

  getIsInsideEdit() {
    return this.isInsideEdit;
  }
  setIsInsideEdit(value) {
    this.isInsideEdit = value;
  }

  private preOrderDate = new Date();

  getDate() {
    return this.preOrderDate;
  }
  setDate(date) {
    this.preOrderDate = date;
  }

  openSnack(message, action) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  openAddStaffDialog(componentName, height, width, dataModel, maxwidth) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth: maxwidth,
    });
  }
  openDialogBox(componentName, height, width, dataModel, maxwidth) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth: maxwidth,
    });
  }

  openvendorStaffDialog(
    componentName,
    height,
    width,
    dataModel,
    maxwidth,
    panelclass
  ) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: false,
      maxWidth: maxwidth,
      panelClass: panelclass,
    });
  }

  openFilterDepartment(
    componentName,
    panelClass,
    height,
    width,
    data,
    maxHeight?
  ) {
    return this.dialog.open(componentName, {
      panelClass: panelClass,
      width: width,
      height: height,
      data: data,
      disableClose: true,
      maxHeight: maxHeight,
    });
  }

  openbottomsheet(componentName, dataModel) {
    return this._bottomSheet.open(componentName, {
      data: dataModel,
      disableClose: true,
    });
  }

  getAllStaff() {
    return this.http.get(
      environment.baseUrl +
        `/user/staff/ist/all?pageNo=0&size=40900000&staffType=COLIVING`
    );
  }

  createsites(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/site/create/site`,
      data
    );
  }

  getallsites(pageNo, size) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/site/ist?pageNo=${pageNo}&size=${size}`
    );
  }
  getAllSitesWithoutPagination(pageNo, size) {
    // Please use pageno and size akash
    return this.http.get(
      environment.baseUrl + `/foodcourt/site/v1/ist/all/sites`
    );
  }

  getallSiteswithPagination(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/site/v1/ist/paged?pageNo=${pageNo}&size=${size}`
    );
  }

  getallsiteTypes() {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/site/ist/configuration/list/siteType?pageNo=0&size=1000`
    );
  }

  getsitebyid(siteid) {
    return this.http.get(environment.baseUrl + `/foodcourt/site/ist/${siteid}`);
  }

  getsitebyidInstituteVendor(siteid) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/site/ist/${siteid}`,
      {
        headers: { Authorization: this._db.getToken() },
      }
    );
  }

  updatesite(data) {
    return this.http.put(environment.baseUrl + `/foodcourt/site/update`, data);
  }

  getFoodcourtsByid(siteId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/list/${siteId}/foodCourt`
    );
  }

  getFoocourtDetailsByFoodcourtId(foodcourtId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/${foodcourtId}`
    );
  }
  getFCbyFCIdV2(foodcourtId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/${foodcourtId}/foodCourtCAM`
    );
  }

  getFcCamChargesByFcId(foodCourtId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/${foodCourtId}/cam/latest`
    );
  }

  deletesite(siteId) {
    return this.http.delete(
      environment.baseUrl + `/foodcourt/site/delete/${siteId}`
    );
  }

  createfoodcourt(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/foodCourt/create`,
      data
    );
  }

  getallfoodcourt(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/list?pageNo=${pageNo}&size=${size}`
    );
  }

  updatefoodcourt(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/foodCourt/update`,
      data
    );
  }

  getAllfoodcourtCam(siteid) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/foodCourt/CAM?&pageNo=${0}&siteId=${siteid}&size=${10}`
    );
  }

  deletefoocourt(foodcourtid) {
    return this.http.delete(
      environment.baseUrl + `/foodcourt/foodCourt/delete/${foodcourtid}`
    );
  }

  createvendor(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/vendor/vendor`,
      data
    );
  }

  // updatevendormobile(mobileNo, vendorId, data) {
  //   return this.http.put(
  //     environment.baseUrl +
  //     `/foodcourt/vendor/vendor/update/mobile?mobile=${mobileNo}&vendorId=${vendorId}`,
  //     data
  //   );
  // }

  getallvendors(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor?pageNo=${pageNo}&size=${size}`
    );
  }
  getAllVendorsFCID(fcId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/foodCourt/vendors?foodCourtId=${fcId}`
    );
  }
  getAllCountersById(fcId, siteId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/counters/fcAndSiteId?fcId=${fcId}&siteId=${siteId}`
    );
  }
  getvendorbyId(vendorid) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/vendor/ist/vendor/${vendorid}`
    );
  }

  addvendor(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/vendor/vendor/add`,
      data
    );
  }

  deletevendorbyId(vendorid) {
    return this.http.delete(
      environment.baseUrl + `/foodcourt/vendor/vendor/${vendorid}`
    );
  }

  updatevendor(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/vendor/vendor/update`,
      data
    );
  }

  getallCity() {
    return this.http.get(environment.baseUrl + `/property/master/ist/all`);
  }

  getalllocalityBycity(data) {
    return this.http.put(
      environment.baseUrl + `/property/master/ist/citylist/localities`,
      data
    );
  }

  filtersites(data, pageNo, size) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/site/ist/filter/site?pageNo=${pageNo}&size=${size}`,
      data
    );
  }

  searchsite(searchdata, pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/site/ist/search?name=${searchdata}&pageNo=${pageNo}&size=${size}`
    );
  }

  searchvendor(searchvendordata) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor/search?name=${searchvendordata}`
    );
  }

  searchvendorbysiteid(siteid, name, pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor/search/${siteid}?name=${name}&pageNo=${pageNo}&size=${size}`
    );
  }

  foodcourtonvendor(vendorId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor/foodCourtList?vendorId=${vendorId}`
    );
  }

  foodcourtbyvendorId(vendorId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor/foodCourtList?vendorId=${vendorId}`
    );
  }

  updatevendortiming(endtime, foodcourtid, starttime, vendorid, data) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/vendor/ist/v1/timing/vendor?endTime=${endtime}&foodCourtId=${foodcourtid}&startTime=${starttime}&vendorId=${vendorid}`,
      data
    );
  }
  getFcListbyVIdandSiteId(data) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor/${data.vendorId}/site?siteId=${data.siteId}`
    );
  }

  foodcourtbysiteId(siteId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/list/${siteId}/foodCourt`
    );
  }

  searchfoodcourt(foodcourtname) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/search?name=${foodcourtname}&pageNo=${0}&size=${10}`
    );
  }

  filterfoodcourt(data) {
    if (data.foodCourtName?.length === 0) {
      data.foodCourtName = null;
    }
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/filter?pageNo=${0}&size=${100000}`,
      data
    );
  }

  tooglefoodcourtbyId(foodcourtid, status, data) {
    const staffId = this._db.getStaffId();
    const staffName = this._db.getUserName();

    return this.http.put(
      environment.baseUrl +
        `/foodcourt/foodCourt/update/${foodcourtid}/status?value=${status}&staffId=${staffId}&staffName=${staffName}`,
      data
    );
  }

  tooglevendor(activestatus, vendorid, data) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/vendor/vendor/update/status?value=${activestatus}&vendorId=${vendorid}`,
      data
    );
  }

  tooglestatusvendorsite(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/vendor/v1/update/vendor/site`,
      data
    );
  }

  tooglestatusvendorfoodcourt(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/vendor/v1/update/vendor/food-court`,
      data
    );
  }

  tooglesite(data) {
    const staffId = this._db.getStaffId();
    const staffName = this._db.getUserName();

    return this.http.put(
      environment.baseUrl +
        `/foodcourt/site/update/status?siteId=${data.siteId}&value=${data.activestatus}&staffId=${staffId}&staffName=${staffName}`,
      data
    );
  }

  filtervendor(vendordata, pageNo, size) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor/filter?pageNo=${pageNo}&size=${size}`,
      vendordata
    );
  }

  filterSiteVendor(vendordata, pageNo, size) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor/filter/new/erp?pageNo=${pageNo}&size=${size}`,
      vendordata
    );
  }

  getvendorsbyfoodcourt(siteid) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/vendor/ist/v1/list/${siteid}`
    );
  }

  getvendorsBysiteIdWithPagination(siteId, pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/v1/${siteId}/paged?pageNo=${pageNo}&size=${size}`
    );
  }

  terminateVendor(payload: any) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/foodCourt/ist/counter/terminate/vendor`,
      payload
    );
  }

  getVendorByFcId(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/foodCourt/ist/vendors`,
      data
    );
  }

  getFoodCourtListBySiteId(siteId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/list/${siteId}/foodCourt`
    );
  }

  getStaff(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/user/staff/ist/all?pageNo=${pageNo}&size=${size}&staffType=${'COLIVING'}`
    );
  }

  getstaffbyid(staffid) {
    return this.http.get(
      environment.baseUrl +
        `/user/staff/ist?allDetails=${true}&staffId=${staffid}`
    );
  }

  searchfoodcourtbyid(searchdata, siteid) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/search?name=${searchdata}&pageNo=${0}&siteId=${siteid}&size=${400000}`
    );
  }

  getfoodcourtsbysiteids(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/site/ist/foodCourts`,
      data
    );
  }

  getallLocality() {
    return this.http.get(environment.baseUrl + `/property/master/ist/locality`);
  }

  addEstimation(data, actualvalue) {
    // return this.http.post(
    //   environment.baseUrl +
    //     `/foodcourt/foodCourt/ist/CAM/create?isActual=${actualvalue}`,
    //   data
    // );

    return this.http.post(
      environment.baseUrl + '/foodcourt/foodCourt/ist/CAM/create',
      data
    );
  }

  updateActualCharges(data, camId) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/${camId}/actual/update/CAM`,
      data
    );
  }

  getfoodcourtbycamfoodcourtid(foodCourtId, pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/foodCourt/CAM?foodCourtId=${foodCourtId}&pageNo=${0}&size=${10}`
    );
  }
  // getfoodcourtbycamfoodcourtids( foodcourtId){
  //   return this.http.get(
  //     environment.baseUrl+`/foodcourt/foodCourt/ist/${foodcourtId}/foodCourtCAM`
  //   )
  // }

  getfoodcourtcam(value) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/foodCourt/CAM/search?value=${value}`
    );
  }

  getcambycamid(camId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/get/CAM?camId=${camId}`
    );
  }

  // New api for new camcharges changes

  // Below api is used for adding and updating actual and estimated cam charges
  addEstimatedCamCharges(camData) {
    return this.http.post(
      environment.baseUrl + '/foodcourt/foodCourt/cam',
      camData
    );
  }

  getCamChargesList(siteId: string, isActual: boolean, month: any) {
    if (isActual) {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/foodCourt/ist/cam?isActual=true&month=${month}&siteId=${siteId}`
      );
    } else {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/foodCourt/ist/cam?isActual=false&siteId=${siteId}`
      );
    }
  }

  getFoodcourtCamDetails(
    fcId: string,
    siteId: string,
    isActual: boolean,
    month: any
  ) {
    if (isActual) {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/foodCourt/ist/cam?fcId=${fcId}&isActual=true&month=${month}&siteId=${siteId}`
      );
    } else {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/foodCourt/ist/cam?fcId=${fcId}&isActual=false&siteId=${siteId}`
      );
    }
  }

  getGlobalCamChargesList(
    fcId: string,
    isActual: boolean,
    pageNo: number,
    size: number
  ) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/global/cam?isActual=${isActual}&fcId=${fcId}&pageNo=${pageNo}&size=${size}`
    );
  }

  searchForCamCharges(
    searchedName: string,
    siteId: string,
    fcId: string,
    isActual: boolean,
    pageNo: number,
    size: number,
    startDate: string = '',
    endDate: string = ''
  ) {
    if (!startDate) {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/foodCourt/ist/search/cam/charges?foodCourtName=${searchedName}&siteId=${siteId}&fcId=${fcId}&isActual=${isActual}&pageNo=${pageNo}&size=${size}`
      );
    } else {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/foodCourt/ist/search/cam/charges?foodCourtName=${searchedName}&siteId=${siteId}&fcId=${fcId}&isActual=${isActual}&pageNo=${pageNo}&size=${size}&startDate=${startDate}&endDate=${endDate}`
      );
    }
  }

  //pagination testing api

  getAllVendorPagination(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor/paged?pageNo=${pageNo}&size=${size}`
    );
  }

  downloadcamchargesActualcharges(fcId, month, year) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/ist/cam-configuration?fcId=${fcId}&month=${month}&year=${year}`,
      { observe: 'response', responseType: 'blob' }
    );
  }
  downloadcamchargesEstimatecharges(fcId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/cam-configuration?fcId=${fcId}`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  downloadPlatformAndRentalExcel(startDate: string, endDate: string) {
    return this.http.get(
      environment.baseUrl +
        `/report/soa/ist/export/platform/charge?startDate=${startDate}&endDate=${endDate}`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  downloadposData(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/POS/ist/v1/download/ppos.xlsx`,
      data,
      { observe: 'response', responseType: 'blob' }
    );
  }
  UploadPosdata(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/POS/ist/v1/update/ppos/data`,
      data
    );
  }

  uploadCamChargesData(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/ist/ist/update-cam-charges`,
      data
    );
  }

  downloadSampleFileForPosCreation(payload) {
    return this.http.put(
      environment.baseUrl + '/foodcourt/POS/ist/v1/ppos/sample/download',
      payload,
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  posdataBulkCreation(payload) {
    return this.http.put(
      environment.baseUrl + '/foodcourt/POS/ist/pos/bulk/creation',
      payload
    );
  }

  //getProperties
  getpropertiesonsearch(data) {
    return this.http.get(
      environment.baseUrl +
        `/property/property/v2/ist/add-property/search?propertyName=${data}`
    );
  }

  getmenuByvendorIdAndFc(foodCourtId: any, vendorId: any) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/menu/ist/menu/data/v2?foodCourtId=${foodCourtId}&vendorId=${vendorId}`
    );
  }

  createSubscription(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/subscription/plan`,
      data
    );
    // return this.http.post(
    //   environment.baseUrl + `/foodcourt/subscription/ist/plan`, data
    // )
  }

  getSubscriptionList(pageNo, size, type) {
    // return this.http.get(
    //   environment.baseUrl + `/foodcourt/subscription/ist/plan?pageNo=${pageNo}&size=${size}`
    // )
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/subscription/ist/plan/paginated?pageNo=${pageNo}&pageSize=${size}&type=${type}`
    );
  }

  changePlanStatus(status, planId) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/subscription/ist/plan/status?isActive=${status}&planId=${planId}`,
      {}
    );
  }

  updateFoodSubscription(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/subscription/plan`,
      data
    );
  }

  foodSubscriptionById(planId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/subscription/ist/plan/${planId}`
    );
  }

  getAllProperties() {
    return this.http.get(environment.baseUrl + `/property/property/ist/list`);
  }
  getAllFoodcourts(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/list?pageNo=${pageNo}&size=${size}`
    );
  }
  getAllVendors(pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor?pageNo=${pageNo}&size=${size}`
    );
  }

  // getAllPropertyPlans(pageNo, size, propertyId) {
  //   return this.http.get(
  //     environment.baseUrl + `/foodcourt/subscription/ist/plan/property?pageNo=${pageNo}&propertyId=${propertyId}&size=${size}`
  //   )
  // }

  getUserSubscription(
    pageNo: number,
    size: number,
    type: string,
    fcId: string
  ) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/subscription/ist/plan/user/paginated?pageNo=${pageNo}&pageSize=${size}&type=${type}&fcId=${fcId}`
    );
  }

  getAllPropertyFoodSubscription(
    pageNo: number,
    size: number,
    propertyId: string
  ) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/subscription/ist/plan/property?pageNo=${pageNo}&size=${size}&propertyId=${propertyId}`
    );
  }

  //Foodcourt Subscription

  // searchFoodCourtBySubscriptionTitle(title, pageNo, size) {
  //   return this.http.get(
  //     environment.baseUrl + `/foodcourt/subscription/ist/plan/subscription/title?pageNo=${pageNo}&size=${size}&title=${title}`
  //   )
  // }
  searchFoodCourtBySubscriptionTitle(title, pageNo, size, type) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/subscription/ist/plan/search/paginated?pageNo=${pageNo}&pageSize=${size}&searchRequest=${title}&type=${type}`
    );
  }

  filterFoodcourtSubscription(data, pageNo, size) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/subscription/ist/plan/filter?pageNo=${pageNo}&size=${size}`,
      data
    );
  }

  //User Foodcourt Subscription
  searchForUsersubscription(userName, pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/subscription/ist/plan/subscription/username?pageNo=${pageNo}&size=${size}&userName=${userName}`
    );
  }

  getuserSubscriptionDetailsByPlanId(planId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/subscription/user-subscription/${planId}`
    );
  }

  downloadUserFoodSubscriptionByPropertyId(data) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/subscription/ist/ist/v1/download/plan/date-range`,
      data,
      { observe: 'response', responseType: 'blob' }
    );
  }

  // Foodcourt subscription flow

  assignVendorToFcSubscription(planId: string, vendorId: string) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/subscription/ist/vendor/assign?planId=${planId}&vendorId=${vendorId}`,
      {}
    );
  }

  getFcSubDashboardData() {
    return this.http.get(
      environment.baseUrl + '/foodcourt/subscription/ist/fc/dashboard/erp/main'
    );
  }

  getVendorSubDashboardData() {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/subscription/ist/vendor/dashboard/erp/main`
    );
  }

  //pre-ordering in foodcourt site

  getPreOrderItemListBoard(date, foodCourtId, siteId, vendorId) {
    let url = `/foodcourt/ist/order/get/v1/preorders/itemlevel?date=${date}&siteId=${siteId}`;
    if (foodCourtId) {
      url += `&foodCourtId=${foodCourtId}`;
    }
    if (vendorId) {
      url += `&vendorId=${vendorId}`;
    }
    return this.http.get(environment.baseUrl + url);
  }
  //storing in local storage
  setstartdate(body: any) {
    localStorage.setItem('startdate', JSON.stringify(body));
  }

  //order level list in pre-ordering
  getPreOrderLevelList(date, foodCourtId, siteId, pageNo, size) {
    let url = `/foodcourt/ist/order/get/v1/vendor/preorders?date=${date}&siteId=${siteId}&pageNo=${pageNo}&size=${size}`;
    if (foodCourtId) {
      url += `&foodCourtId=${foodCourtId}`;
    }
    return this.http.get(environment.baseUrl + url);
  }
  //for preorder orderlevel filter
  getPreOrderFilter(
    date,
    foodcourtId,
    mealCategory,
    orderType,
    siteId,
    pageNo,
    size
  ) {
    if (mealCategory && orderType) {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/ist/order/get/v1/vendor/preorders?date=${date}&foodcourtId=${foodcourtId}&mealCategory=${mealCategory}&orderType=${orderType}&siteId=${siteId}&pageNo=${pageNo}&size=${size}`
      );
    } else if (mealCategory) {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/ist/order/get/v1/vendor/preorders?date=${date}&foodcourtId=${foodcourtId}&mealCategory=${mealCategory}&siteId=${siteId}&pageNo=${pageNo}&size=${size}`
      );
    } else if (orderType) {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/ist/order/get/v1/vendor/preorders?date=${date}&foodcourtId=${foodcourtId}&orderType=${orderType}&siteId=${siteId}&pageNo=${pageNo}&size=${size}`
      );
    }
  }
  //get order level view by order id
  getPreOrderById(orderId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/order/getPreOrder/${orderId}`
    );
  }

  searchByOrderLevel(
    date,
    foodcourtId,
    orderNum,
    searchtext,
    siteId,
    pageNo,
    size
  ) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/ist/order/get/v1/vendor/preorders?date=${date}&foodcourtId=${foodcourtId}&orderNum=${orderNum}&searchText=${searchtext}&siteId=${siteId}&pageNo=${pageNo}&size=${size}`
    );
  }

  getUserByMobileNumber(mobileNumber) {
    return this.http.post(
      environment.baseUrl + `/user/user/ist/user/mobile?mobile=${mobileNumber}`,
      {}
    );
  }

  getCreateuserSubscrpition(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/subscription/ist/create/bulk/user`,
      data
    );
  }

  searchuserSubcriptionWithProperty(
    searchText: any,
    propertyId: any,
    pageNo: any,
    size: any
  ) {
    if (propertyId) {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/subscription/plan/user/subscription/${searchText}?pageNo=${pageNo}&propertyId=${propertyId}&size=${size}`
      );
    } else {
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/subscription/plan/user/subscription/${searchText}?pageNo=${pageNo}&size=${size}`
      );
    }
  }

  searchUserSubscription(searchText: any, pageNo: any, size: any, type) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/subscription/ist/plan/user/search/paginated?pageNo=${pageNo}&pageSize=${size}&searchRequest=${searchText}&type=${type}`
    );
  }

  getUserSubscriptionDashboardDatawithDate(data) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/subscription/ist/usersubscription/dashboard/date-range`,
      data
    );
  }

  getUserSubscriptionDashboardData() {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/subscription/ist/usersubscription/dashboard/date-range`,
      {}
    );
  }

  userSubscriptionFilterstartEnd(data, pageNo, size) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/subscription/user/filter?pageNo=${pageNo}&size=${size}`,
      data
    );
  }

  todaysSubscriptionList(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/subscription/ist/todaysubscription`,
      data
    );
  }
  searchByVendorName(data) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/vendor/ist/vendor/search?name=${data}`
    );
  }

  getVendorDataSubcription() {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/vendor/ist/vendor/paged?pageNo=${0}&size=${20}`
    );
  }

  getSubscriptionPlansNewByPropertyId(propertyId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/subscription/ist/plans/new/${propertyId}`
    );
  }

  // Corporate Admin
  getAllAdminList() {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/corporate/partner/ist/getAll?pageNo=0&size=1000`
    );
  }

  createAdmin(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/corporate/partner/ist/create`,
      data
    );
  }

  searchFoodcourtByName(
    foodcourtName: string,
    pageNo: number = 0,
    size: number = 10000
  ) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/foodCourt/ist/search?name=${foodcourtName}&pageNo=${pageNo}&size=${size}`
    );
  }

  getFoodcourtOwnersList(pageNo: number, size: number) {
    return this.http.get(
      environment.baseUrl + '/foodcourt/owner/ist/list/paged'
    );
  }

  searchForFcOwner(searchedOwnerName: string, pageNo: number, size: number) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/owner/ist/search?pageNo=${pageNo}&size=${size}&searchRequest=${searchedOwnerName}`
    );
  }

  getAllUntriggredOrderListing(
    startDate: string,
    endDate: string,
    fcId: string,
    pageNo: number,
    size: number
  ) {
    if (fcId)
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/ist/order/get/unTriggered/orders?eDate=${endDate}&sDate=${startDate}&fcId=${fcId}&pageNo=${pageNo}&size=${size}`
      );
    else
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/ist/order/get/unTriggered/orders?eDate=${endDate}&sDate=${startDate}&pageNo=${pageNo}&size=${size}`
      );
  }

  /**
   *
   * @param orderIdList first 50 order IDs
   */
  triggerUntriggredOrder(orderIdList: any) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/ist/order/trigger/order/manually`,
      orderIdList
    );
  }

  // terminal section

  getAllTerminal(data: any) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/terminal/fetch/`,
      data
    );
  }

  searchTerminal(data: any) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/terminal/fetch/`,
      data
    );
  }

  filterTerminal(data: any) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/terminal/fetch/`,
      data
    );
  }

  createTerminal(data: any) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/terminal/collection/point`,
      data
    );
  }

  uploadTerminalDocument(data: any) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/terminal/docs/`,
      data
    );
  }

  //  pullDB API's

  getAllPullDbList(
    endDate: any,
    foodCourtId: any,
    foodCourtName: any,
    pageNo: any,
    size: any,
    startDate: any
  ) {
    let basePath = '/foodcourt/POS/ist/get/pullDb/list?';
    if (foodCourtId) {
      basePath = basePath.concat(
        `foodCourtId=${foodCourtId}&pageNo=${pageNo}&size=${size}&`
      );
    } else if (foodCourtName) {
      basePath = basePath.concat(
        `foodCourtName=${foodCourtName}&pageNo=${pageNo}&size=${size}&`
      );
    } else {
      basePath = basePath.concat(`pageNo=${pageNo}&size=${size}&`);
    }
    if (startDate) {
      basePath = basePath.concat(
        `startingDate=${endDate}&endDate=${startDate}`
      );
    }
    console.log('req', basePath);
    return this.http.get(environment.baseUrl + basePath);
  }

  downloadFile(fileUrl) {
    // Replace 'your-file-url' with the actual URL of the file you want to download

    // Make a GET request to fetch the file
    this.http
      .get(fileUrl, { responseType: 'blob' })
      .subscribe((response: Blob) => {
        // Create a URL for the blob
        const blob = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'filename.db'; // Specify the filename with extension

        // Programmatically click the anchor to trigger the download
        anchor.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      });
  }

  getAllDayEndData(
    endDate: any,
    foodCourtId: any,
    foodCourtName: any,
    pageNo: any,
    size: any,
    startingDate: any
  ) {
    let basePath = '/foodcourt/POS/ist/closing/report?';
    if (foodCourtId) {
      basePath = basePath.concat(
        `foodCourtId=${foodCourtId}&pageNo=${pageNo}&size=${size}&`
      );
    }
    else if(foodCourtName) {
      basePath = basePath.concat(
        `foodCourtName=${foodCourtName}&pageNo=${pageNo}&size=${size}&`
      );
    } else {
      basePath = basePath.concat(`pageNo=${pageNo}&size=${size}&`);
    }
    if (startingDate){
      basePath = basePath.concat(
        `startingDate=${endDate}&endDate=${startingDate}`
      )
    }
    console.log('req' , basePath)
    return this.http.get(environment.baseUrl + basePath);
  }

  getClosingReportListing(endDate:any,foodCourtId:any, pageNo:any, size:any,startingDate:any){
    return this.http.get(
      environment.baseUrl + `/foodcourt/POS/ist/closing/report?endDate=${endDate}&foodCourtId=${foodCourtId}&pageNo=${pageNo}&size=${size}&startingDate=${startingDate}`
    )
  }


  // live POS api

  getLivePosData(foodCourtId:any){
    if (foodCourtId)
      return this.http.get(
        environment.baseUrl + `/foodcourt/POS/ist/deviceInfo/list?foodCourtId=${foodCourtId}`
      );else
      return this.http.get(
        environment.baseUrl + `/foodcourt/POS/ist/deviceInfo/list`
      )
  }

  // download excel
   getDownLoadExcel(data:any){
    return this.http.get(
      `${environment.serverIP}/report/ist/download/closing/report/?endDate=${data.endDate}&startingDate=${data.startDate}${data.selectedFoodcourt ? `&foodCourtId=${data.selectedFoodcourt}` : ''}`

      ,{ observe: 'response', responseType: 'blob' }
  )
   }

  //  pos filter
  getPosFilterData(orderPlatform:any, status:any){
    return this.http.get(
      environment.baseUrl + `/foodcourt/POS/ist/deviceInfo/list?orderPlatform=${orderPlatform}&status=${status}`
    )
  }

  // create EDC mapping based on the foodcourts

  getPosDeviceByfoodcourt(foodCourtId:any, orderPlatform:any, vendorId:any){
    return this.http.get(
      environment.baseUrl + `/foodcourt/POS/ist/POS/list?foodCourtId=${foodCourtId}&orderPlatform=${orderPlatform}&vendorId=${vendorId}`
    )
  }

  // create edc mapping

  createedcMapping(data:any){
    return this.http.post(
      environment.baseUrl + `/foodcourt/POS/ist/PineLabs/Config`,data
    )
  }

  // list edc device
  getedcDeviceList(data:any){
    return this.http.put(
      environment.baseUrl + `/foodcourt/POS/ist/PineLabs/search`,data
    )
  }

  // search edc device

  searchedcDevice(name:any ,pageNo:any, size:any ){
    return this.http.get(
      environment.baseUrl + `/foodcourt/POS/ist/pineLabs/search/paged?name=${name}&pageNo=${pageNo}&size=${size}`
    )
  }

  // view edc device

  getedcViewData(deviceConfigId:any, pageNo:any, size:any){
    return this.http.get(
      environment.baseUrl + `/foodcourt/POS/ist/PineLabs/device/timeline?deviceConfigId=${deviceConfigId}&pageNo=${pageNo}&size=${size}`
    )
  }

  // toggle button api edc

  toggleedcactive(data:any){
    return this.http.post(
      environment.baseUrl + `/foodcourt/POS/ist/PineLabs/Config`,data
    )
  }

  // edc filter

  edcFilterPut(data:any){
    return this.http.put(
      environment.baseUrl + `/foodcourt/POS/ist/PineLabs/search`,data
    )
  }


}
