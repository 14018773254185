import { PopupDbService } from './../../../../authentication/services/popup-db.service';
import { EnquiryService } from './../../enquiry.service';
import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadAwsService } from './../../../../../../services/image-upload-aws.service';



@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ds: DomSanitizer,
    private awsUpload : ImageUploadAwsService,
    private enqService : EnquiryService,
    private dbService : PopupDbService
  ) { }

  staffId;
  ngOnInit(): void {
    this.staffId = this.dbService.getStaffId();
    // console.log(this.data);
  }
  closebtn(){
    this.dialogRef.close();
    // console.log(this.data)
  }
  file;
  noImg;
  profileImg ;
  uploadLoader: boolean = false;
  fileChange(e) {
    // // console.log(e);

    const file = e.srcElement.files[0];
    this.file = file;
    this.noImg = window.URL.createObjectURL(file);
    this.profileImg = window.URL.createObjectURL(file);
    // this.user.profileImg = await this.imageUpload.uploadProfileImage(file);
    // this.user.profileImg = this.user.profileImg.Location;
    // // console.log(this.profileImg);
  }
  async setProfile(){
    if (this.file == '' || this.file == null) {
      this.profileImg ;
    } else {
      this.uploadLoader = true;
      this.profileImg = await this.awsUpload.uploadFile(this.file, "erp-profile-images");
      this.profileImg = this.profileImg.url;
      this.uploadLoader = false;
      // // console.log(this.profileImg)
      if(this.data.response=='staff'){
        let staff ={
          staffBasic:{
            contactDetails :{
              imageUrl : this.profileImg,
              mobile:{
                countryCode : '91',
                mobile:this.data.data.contactDetails.mobile.mobile
              },
              name:this.data.data.contactDetails.name,
              personalEmailId: this.data.data.contactDetails.personalEmailId,
              workEmailId :this.data.data.contactDetails.workEmailId
            }
          },
          staffStatus: {
            remarks: "Propfile Pic Updated",
            status: "OTHER_UPDATE"
          }
        }
        this.enqService.updateStaffProfile(staff,this.staffId).subscribe(
          (res:any) => {
            // console.log(res);
          },
          (err) => {
            // console.log(err);
          }
        )
      }
      else{
        
      }
    
    }
    // this.closebtn();
    this.dialogRef.close(this.profileImg);
  }
}
