<section id="landing-console">
  <div class="main-landing-section" *ngIf="srcWidth > 476; else outsideClick">
    <div *ngIf="profileBox" class="profile-box">
      <div class="profile-in"><app-profile></app-profile></div>
    </div>
    <div class="side-panel" [ngClass]="expand ? 'expand' : 'close'">
      <app-side-panel [expantion]="expand" (click)="sidePannelopen()" [srcWidth]="srcWidth"
        [panelName]="configuration"></app-side-panel>
    </div>
    <div [ngClass]="expand ? 'component-section' : 'component-section-full'">
      <div class="toggle-button">
        <div class="img-tag">
          <img (click)="sidePannelToggle()" src="./../../../../assets/images/topnav/expansion-btn.svg" width="20px"
            alt="expand" class="expansion-btn" />
        </div>
        <div class="image-section">
          <div *ngIf="srcWidth > 476; else smallScreenLogo"></div>
          <ng-template #smallScreenLogo>
            <img src="./../../../../assets/images/common/smallscreen-logo.svg" alt="" />
          </ng-template>
        </div>
        <div class="list-profile">
          <!-- <img
            class="notification"
            src="./../../../../assets/images/common/notification.svg"
            alt="notificatio"
          /> -->
          <img src="./../../../../assets/images/common/configurations.svg" alt="configurations"
            (click)="openConfiguration()" [ngClass]="configuration === 'true' ? 'config-change' : 'notification'" />

          <!-- <div class="staff-name">{{this.staffName | titlecase}}</div> -->
          <div class="profile-section" #profile>
            <div class="name-tag profile" *ngIf="
                !imgUrl;
                else profileImg
              " (click)="openProfile()">
              <div class="name">{{ nameletter | uppercase }}</div>
            </div>
            <ng-template #profileImg>
              <img class="profile" [src]="imgUrl" alt="profile" (click)="openProfile()" />
            </ng-template>
            <div *ngIf="profileBox" class="profile-box">
              <div class="profile-in"><app-profile></app-profile></div>
            </div>
          </div>
          <!-- <div class="logout" (click)="logout()">Logout</div> -->
        </div>
      </div>
      <div class="other-major-erp-section">
        <ng-container *ngIf="!loadingRouteConfig">
          <router-outlet></router-outlet>
        </ng-container>
        <ng-container *ngIf="loadingRouteConfig">
          <div class="img-main-loader">
            <img align="center" src="./../../../../assets/images/common/istharaloader.gif" alt="loader" />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template #outsideClick>
    <div class="main-landing-section">
      <div class="component-section">
        <div class="toggle-button">
          <div class="img-tag" #txt>
            <img (click)="sidePannelToggle()" src="./../../../../assets/images/topnav/expansion-btn.svg" width="20px"
              alt="expand" class="expansion-btn" />
            <div class="side-panel" [ngClass]="expand ? 'expand' : 'close'">
              <app-side-panel [expantion]="expand" [srcWidth]="srcWidth" [panelName]="configuration"
                (closePanel)="sidePannelToggle()"></app-side-panel>
            </div>
          </div>
          <div class="image-section">
            <div *ngIf="srcWidth > 476; else smallScreenLogo"></div>
            <ng-template #smallScreenLogo>
              <img src="./../../../../assets/images/common/smallscreen-logo.svg" alt="" />
            </ng-template>
          </div>
          <div class="list-profile">
            <!-- <img
              class="notification"
              src="./../../../../assets/images/common/notification.svg"
              alt="notificatio"
            /> -->
            <!-- <div class="staff-name">{{this.staffName | titlecase}}</div> -->
            <img src="./../../../../assets/images/common/configurations.svg" alt="configurations"
              (click)="openConfiguration()" [ngClass]="configuration === 'true' ? 'config-change' : 'notification'" />
            <!-- <img
              class="profile"
              src="./../../../../assets/images/common/profile1.svg"
              alt="profile"
              (click)="openProfile()"
            />
            <div *ngIf="profileBox" class="profile-box">
              <div class="profile-in"><app-profile></app-profile></div>
            </div> -->
            <div class="profile-section" #profile>
              <div class="name-tag profile" *ngIf="
                  !imgUrl;
                  else profileImg
                " (click)="openProfile()">
                <div class="name">{{ nameletter | uppercase }}</div>
              </div>
              <ng-template #profileImg>
                <img class="profile" [src]="imgUrl" alt="profile" (click)="openProfile()" />
              </ng-template>
              <div *ngIf="profileBox" class="profile-box">
                <div class="profile-in"><app-profile></app-profile></div>
              </div>
            </div>
            <!-- <div class="logout" (click)="logout()">Logout</div> -->
          </div>
        </div>
        <div class="other-major-erp-section">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ng-template>
</section>
