<div class="drop-files" *ngIf="profileImg === undefined">
<div class="add-profile">
    <div class="upload-btn-wrapper">
        <button class="btn"><img src="./../../../../../../../assets/images/enquiry/addprofile.svg"></button>
        <input type="file" name="myfile"
        (change)="fileChange($event)" accept="image/x-png,image/gif,image/jpeg,image/jpg"/>  
    </div>
</div>
<div class="drop-file">Drag and Drop Files</div>
<!-- <div class="or">or</div>
<div class="webcam">Click photo from your webcam</div> -->
<div class="webcam"></div>
</div>
<div class="profile-img" *ngIf="profileImg  != undefined">
    <img [src]="ds.bypassSecurityTrustUrl(profileImg)" alt="user_img" width="200"
    height="150">
</div>
<div class="bottom-btn">
    <div class="cancel" (click)="closebtn()">
    <button>
        Cancel
    </button>
    </div>
    <ng-template #loading>
        <div class="setprofile">
            <button (click)="setProfile()">
                Set as profile photo
            </button>
        </div>
    </ng-template>
    <div class="setprofile" *ngIf="uploadLoader;else loading">
        <button>
          <i class="fa fa-spinner fa-spin"></i>Loading
        </button>
      </div>
</div>
