<div class="main-section">
    <div class="image-section">
        <div class="image">
            <img [src]="imgUrl" alt="" *ngIf="imgUrl != ''; else normalImg">
            <ng-template #normalImg>
                <img src="./../../../../../assets/images/food-court/istharafoodcourt.svg" alt="">
            </ng-template>
        </div>
        <div class="site-title">
            {{data.siteName | titlecase}}
        </div>
    </div>
    <div class="content-section">
        <img
        (click)="onNoClick()"
        class="close-btn"
        src="./../../../../../../../assets/images/common/close.svg"
        alt="close"
        />
        <div class="details">
            <div class="phone">
                <div class="text-img">
                    <img src="./../../../../../assets/images/side-panel/phone.svg" alt="">
                </div>
                <div class="phn-text">{{data.contactDetails.mobile ? data.contactDetails.mobile : 'N/A'}}</div>
            </div>
            <div class="phone">
                <div class="text-img">
                    <img src="./../../../../../assets/images/side-panel/email.svg" alt="">
                </div>
                <div class="phn-text">
                    {{data.contactDetails.email ? data.contactDetails.email : 'N/A'}}
                </div>
            </div>
            <div class="phone">
                <div class="text-img">
                    <img src="./../../../../../assets/images/side-panel/globe.svg" alt="">
                </div>
                <div class="phn-text">
                    <a [href]="data.webLink">{{data.webLink ? data.webLink : "N/A"}}</a>
                </div>
            </div>
            <div class="phone">
                <div class="text-img">
                    <img src="./../../../../../assets/images/side-panel/pin.svg" alt="">
                </div>
                <div class="address-text" *ngIf="data.addressDetails; else na">
                    <span *ngIf="data.addressDetails.addressLine1 != null">{{data.addressDetails.addressLine1}},</span>&nbsp;
                    <span *ngIf="data.addressDetails.addressLine2 != null">{{data.addressDetails.addressLine2}},</span>&nbsp;
                    <span *ngIf="data.addressDetails.locality != null">{{data.addressDetails.locality}},</span>&nbsp;
                    <span *ngIf="data.addressDetails.city != null">{{data.addressDetails.city}}</span>
                </div>
                <ng-template #na>
                    <div class="address-text">N/A</div>
                </ng-template>
            </div>
        </div>
    </div>
</div>