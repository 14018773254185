import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EditProfileComponent } from '../../major-erp-components/enquiry-erp/enquiry-sub-components/edit-profile/edit-profile.component';
import { EnquiryService } from '../../major-erp-components/enquiry-erp/enquiry.service';
import { StaffService } from '../../major-erp-components/staff-erp/staff.service';
import { PasswordChangeComponent } from '../password-change/password-change.component';
import { PopupDbService } from './../../authentication/services/popup-db.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss','./mq-profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    // public dialogRef: MatDialogRef<ProfileComponent>,
    // @Inject(MAT_DIALOG_DATA) public data,
    private ds : DomSanitizer,
    private dbService : PopupDbService,
    private enquiryService : EnquiryService,
    private router : Router,
    private staffService: StaffService,
  ) { }

  staffId;
  staffDetails:any;
  profileImg;

  ngOnInit(): void {
    // console.log(this.dbService.getStaffId());
    this.staffId = this.dbService.getStaffId()
    this.getStaffbyId();
    // console.log(this.staffDetails);
  }

  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
  nameletter:'';
  getStaffbyId(){
    this.enquiryService.getstaffbyId(this.staffId).subscribe(
      (res:any) => {
        console.log("ddd",res.data);
        res.data.forEach(element => {
          if(this.staffId === element.staffId){
            this.staffDetails = element;
            this.profileImg = element.contactDetails.imageUrl;
          }
        });
        console.log(this.profileImg);
        console.log("some thing",this.staffDetails)
        let matches = this.staffDetails.contactDetails.name.match(/\b(\w)/g);
        this.nameletter = matches.join('');
        let url = this.profileImg;
        let pattern = /^((http|https|ftp):\/\/)/;
        if(pattern.test(url)){
          this.profileImg = url;
        }
        else{
          this.profileImg = "";
        }
        console.log(this.nameletter);
      },
      (err) => {
        console.log(err);
      }
    )
  }

  editProfile(){
    let callModel = this.enquiryService.editprofile(
      EditProfileComponent,
      'auto',
      '600px',
      {data:this.staffDetails,response:'staff'}
    );
    callModel.afterClosed().subscribe((data) => {
      console.log('profileImg Url', data);
      // this.createEnquir = data;
      this.profileImg = data;
    });
  }
  logout(){
    localStorage.clear();
    this.router.navigate(['/auth']);
  }
  changePassword(){
    let changePassword= this.staffService.openMessageDialog(
      PasswordChangeComponent,
      'auto',
      '451px',
      {staff:this.staffDetails},
      '94vw',
    );
    changePassword.afterClosed().subscribe((data) => {
      console.log('data in enquiry after call close!', data);
    });
  }
}
