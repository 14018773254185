import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appTooltips]'
})
export class TooltipsDirective {

  constructor() { }

  @HostListener('mouseenter') openToolTip(){
    console.log("Tooltip opned");
  }

}
