import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PasswordChangeComponent } from './erp-landing/shared-erp-landing/password-change/password-change.component';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
// import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SiteInstituteInfoComponent } from './erp-landing/shared-erp-landing/site-institute-info/site-institute-info.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ConvertCurrencyPipe } from './erp-landing/pipes/convert-currency.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { TooltipsDirective } from './erp-landing/utils/directories/tooltips.directive';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTabsModule } from '@angular/material/tabs';
import { NumberToTextComponent } from './erp-landing/utils/components/number-to-text/number-to-text.component';
// import { ShowmoreDirective } from './erp-landing/utils/directories/showmore.directive';

export const ConstMaterial = [
  MatGridListModule,
  MatIconModule,
  MatDialogModule,
  MatExpansionModule,
  AutocompleteLibModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  FormsModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatMenuModule,
  MatCardModule,
  NgxSliderModule,
  ClipboardModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
  MatSelectFilterModule,
  MatChipsModule,
  MatBottomSheetModule,
  MatTabsModule
];
export const ConstPrimeNg = [
  CarouselModule,
  ButtonModule,
  ToastModule,
  CalendarModule,
];
@NgModule({
    declarations: [PasswordChangeComponent, SiteInstituteInfoComponent, ConvertCurrencyPipe, TooltipsDirective, NumberToTextComponent,],
    exports: [...ConstMaterial, ...ConstPrimeNg, ConvertCurrencyPipe],
    imports: [CommonModule, ...ConstMaterial, ...ConstPrimeNg]
})
export class ConstantMaterialModule { }
