<section id="side-panel">
  <div class="side-panel-box">
    <div class="logo">
      <img src="./../../../../../../assets/images/side-panel/isthara-logo.svg" loading="lazy" alt="logo" class="logo"
        *ngIf="expantion === true" (click)="logoclick()" />
      <img src="./../../../../../assets/images/side-panel/sliding-logo.svg" loading="lazy" alt="logo" width="100%"
        class="not-expanded-logo" *ngIf="expantion === false" (click)="logoclick()" />
    </div>
    <div (click)="goBack()" [ngClass]="expantion ? 'all-home' : 'expantion-true'">
      <img src="./../../../../../assets/images/side-panel/left-arrow.svg" alt="">
      &nbsp;<span class="go-home" *ngIf="expantion"> Go to Home</span>
    </div>
    <div [ngClass]="expantion === true ? 'side-section-panel' : 'not-expanded'">
      <ng-container *ngFor="let firstLevelItem of configPanel; let i = index">
        <ng-container *ngIf="expantion; else Icons">
          <div class="icon-bar" [ngClass]="!expantion ? 'dropdown' : 'icon-bar'" (click)="onFirstLevelList(i)">
            <div class="side-panel-img">
              <img *ngIf="!firstLevelItem.active" src="./../../../../../assets/images/side-panel/{{
              firstLevelItem.iconName
            }}.svg" alt="dashboard" loading="lazy" width="100%" />
              <img *ngIf="firstLevelItem.active" src="./../../../../../assets/images/side-panel/{{
              firstLevelItem.iconName
            }}_active.svg" alt="dashboard" loading="lazy" width="100%" />
            </div>
            <div class="side-panel-text">
              <span [ngClass]="firstLevelItem.active ? 'dark' : 'default-text'" class="item-name">{{ firstLevelItem.name
                }}</span>
              <span *ngIf="firstLevelItem.list?.length > 0" class="item-arrow"><img
                  src="./../../../../../assets/images/side-panel/arrow-right.svg" alt="arrow" loading="lazy" width="7px"
                  class="arrow" [ngClass]="
                      !firstLevelItem.upDown? 'normal-arrow' : 'rotated-arrow'
                    " />
              </span>
            </div>
          </div>
          <ul class="list-container" *ngIf="firstLevelItem.list?.length > 0 && firstLevelItem.upDown">
            <ng-container *ngFor="let secondLevelItem of firstLevelItem.list; let j = index">
              <li side-panel-text (click)="onSecondLevelList(firstLevelItem, secondLevelItem, j)">
                <span class="default-text" *ngIf="!secondLevelItem.active">
                  <mat-icon>panorama_fish_eye</mat-icon> &nbsp; {{ secondLevelItem.name | titlecase}}
                </span>

                <span class="dark" *ngIf="secondLevelItem.active">
                  <mat-icon>lens</mat-icon> &nbsp; {{ secondLevelItem.name | titlecase}}
                </span>
              </li>
            </ng-container>
          </ul>
        </ng-container>
        <ng-template #Icons>
          <div class="small-side-panel-img" (click)="onIconsSelected(i)">
            <img *ngIf="!firstLevelItem.active" src="./../../../../../assets/images/side-panel/{{
            firstLevelItem.iconName
          }}.svg" alt="dashboard" loading="lazy" width="100%" />
            <img *ngIf="firstLevelItem.active" src="./../../../../../assets/images/side-panel/{{
            firstLevelItem.iconName
          }}_active.svg" alt="dashboard" loading="lazy" width="100%" />
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</section>
