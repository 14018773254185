<section class="audit-section d-flex">
  <div class="logo-container">
    <img
      class="institute-logo"
      src="{{ data.institutionImageUrl }}"
      alt="Logo"
    />
  </div>
  <div class="main-section">
    <div class="close-container">
      <img
        class="close-btn"
        (click)="closePopup()"
        src="./../../../../../../../assets/images/common/close.svg"
        alt="close"
      />
    </div>
    <div class="d-flex">
      <mat-icon>phone</mat-icon>
      <div>{{ data.institutionContactDetails.mobile }}</div>
    </div>
    <div class="d-flex">
      <mat-icon>mail</mat-icon>
      <div>{{ data.institutionContactDetails.email }}</div>
    </div>
    <div class="d-flex">
      <mat-icon>language</mat-icon>
      <div>{{ data.institutionContactDetails.instituteWebLink }}</div>
    </div>
    <div class="d-flex">
      <mat-icon>room</mat-icon>
      <div>{{ data.addressDetails.addressLine1 | titlecase }}</div>
    </div>
    <div class="d-flex">
      <div *ngIf="data.active" class="active status">Active</div>
      <div *ngIf="!data.active" class="inactive status">Inactive</div>
    </div>
  </div>

  <!-- <div (click)="delete()">
        delete
    </div> -->
</section>
