import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupDbService } from '../../../authentication/services/popup-db.service';
import { FoodCourtService } from '../../../major-erp-components/food-court/food-court.service';
import { SiteInstituteInfoComponent } from '../../site-institute-info/site-institute-info.component';

@Component({
  selector: 'app-institution-vendor',
  templateUrl: './institution-vendor.component.html',
  styleUrls: ['./institution-vendor.component.scss'],
})
export class InstitutionVendorComponent implements OnInit {
  siteID = '';
  dataSource;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dbService: PopupDbService,
    private Foodcourtservice: FoodCourtService,
    private _dbService: PopupDbService
  ) { }
  rotate: number = 0;

  @Input() expantion: boolean;
  @Input() srcWidth;
  configPanel = [
    // {
    //   name: 'Dashboard',
    //   urlCheck: 'site-dashboard',
    //   value: 'institution-foodcourt',
    //   iconName: 'dashboard',
    //   upDown: false,
    // },
    {
      active: false,
      name: 'Food Court',
      urlCheck: 'food-court',
      value: 'institution-foodcourt',
      iconName: 'food-courthouse',
      upDown: false,
      queryPath: null,
      list: []
    },
    {
      active: false,
      name: 'Counters',
      urlCheck: 'counters',
      value: 'institution-foodcourt',
      iconName: 'counters',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'dashboard',
          value: 'counters-dashboard',
          active: false,
          queryPath: {
            pageno: 1
          },
          list: []
        },
        {
          name: 'counters',
          value: 'list-counters',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    // {
    //   name: 'Counters',
    //   urlCheck: 'counters',
    //   value: 'institution-foodcourt',
    //   iconName: 'food-counters',
    //   upDown: false,
    //   allParams: false,
    // },
    {
      active: false,
      name: 'Vendor',
      urlCheck: 'vendor',
      value: 'institution-foodcourt',
      iconName: 'vendors',
      upDown: false,
      queryPath: {
        pageno: 1
      },
      list: []
    },
    {
      active: false,
      name: 'Menu',
      urlCheck: 'menu',
      value: 'institution-foodcourt',
      iconName: 'food-menu',
      upDown: false,
      queryPath: {
        pageno: 1
      },
      list: []
    },
    {
      active: false,
      name: 'Pre Orders',
      urlCheck: 'preorder',
      value: 'institution-foodcourt',
      iconName: 'preorder',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Order Level',
          value: 'orderwise-order',
          active: false,
          queryPath: {
            pageno: 1
          },
          list: []
        },
        {
          name: 'Item Level',
          value: 'itemwise-order',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    {
      active: false,
      name: 'Reports',
      urlCheck: 'reports',
      value: 'institution-foodcourt',
      iconName: 'reports',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Sales Summary',
          value: 'foodcourt-report',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Item Summary',
          value: 'itemwise-report',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Invoice Summary',
          value: 'invoice-report',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Vendor Summary',
          value: 'vendor-report',
          active: false,
          queryPath: null,
          list: []
        },
        // {
        //   name: 'Credit Sales Summary',
        //   value: 'credit-sales-report',
        //   active: false,
        //   queryPath: null,
        //   list: []
        // },
        {
          name: 'Markup Sales Summary',
          value: 'markup-report',
          active: false,
          queryPath: null,
          list: []
        },
        // {
        //   name: 'Order Type Summary',
        //   value: 'order-type-report',
        //   active: false,
        //   queryPath: false,
        // },
      ],
    },
    {
      active: false,
      name: 'Operation Team',
      urlCheck: 'operation-team',
      value: 'institution-foodcourt',
      iconName: 'sl-oteam',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Approved Staff',
          value: 'approved-staff',
          active: false,
          queryPath: {
            pageno: 1
          },
          list: []
        },
        {
          name: 'Pending Staff',
          value: 'pending-staff',
          active: false,
          queryPath: {
            pageno: 1
          },
          list: []
        },
      ]
    },
    {
      active: false,
      name: 'Corporate Connect',
      urlCheck: 'corporate-team',
      value: 'institution-foodcourt',
      iconName: 'corporate-connect',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Employer',
          value: 'employers',
          active: false,
          queryPath: {
            pageno: 1
          },
          list: []
        },
        {
          name: 'Employer Admin',
          value: 'employer-admin',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Employees',
          value: 'employees',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: "Invoices",
          value: 'invoices',
          active: false,
          queryPath: {
            pageno: 1
          },
          list: []
        },
        {
          name: "Payment Records",
          value: 'payment-records',
          active: false,
          queryPath: {
            pageno: 1
          },
          list: []
        }
        // {
        //   name: 'Bill to Company',
        //   value: 'bill-to-company',
        //   active: false,
        //   queryPath: false,
        // },
      ],
    },
    // {
    //   name: 'CAM Charges',
    //   urlCheck: 'cam-charges',
    //   value: 'institution-foodcourt',
    //   iconName: 'food-menu',
    //   upDown: false,
    //   allParams: false,
    // },
    {
      active: false,
      name: 'Cam Charges',
      urlCheck: 'cam-charges',
      value: 'institution-foodcourt',
      iconName: 'food-menu',
      upDown: false,
      queryPath: null,
      list: [
        {
          name: 'Estimated',
          value: 'list-estimated',
          active: false,
          queryPath: null,
          list: []
        },
        {
          name: 'Actual',
          value: 'list-actual',
          active: false,
          queryPath: null,
          list: []
        },
      ],
    },
    // {
    //   name: 'Reports',
    //   value: 'institution-details',
    //   iconName: 'sl-reports',
    //   upDown: false,
    //   allParams:false
    // },
    // {
    //   name: 'Attendance',
    //   value: 'institution-details',
    //   iconName: 'sl-attendance',
    //   upDown: false,
    //   allParams: false,
    //   list: [
    //     {
    //       name: 'Category',
    //       value: 'automation-category',
    //       active: false,
    //       queryPath: false,
    //     },
    //     {
    //       name: 'Template',
    //       value: 'template',
    //       active: false,
    //       queryPath: false,
    //     },
    //     {
    //       name: 'Trigger',
    //       value: 'trigger',
    //       active: false,
    //       queryPath: false,
    //     },
    //   ],
    // },

    // {
    //   name: 'Operations Team',
    //   urlCheck:'operationteam',
    //   value: 'institution-details',
    //   iconName: 'sl-oteam',
    //   upDown: false,
    //   allParams:false
    // },
    // {
    //   name: 'Order Status',
    //   urlCheck: 'order-status',
    //   value: 'institution-foodcourt',
    //   iconName: 'order-status',
    //   upDown: false,
    //   allParams: false,
    // },
    {
      active: false,
      name: 'Bulk Orders',
      urlCheck: 'bulk-orders',
      value: 'institution-foodcourt',
      iconName: 'bulk-orders',
      upDown: false,
      queryPath: null,
      list: []
    },
    {
      active: false,
      name: 'Rating & Review',
      urlCheck: 'rating-review',
      value: 'institution-foodcourt',
      iconName: 'rating-review',
      upDown: false,
      queryPath: {
        pageno: 1
      },
      list: []
    },
    {
      active: false,
      name: 'Offline Pos System',
      urlCheck: 'offline-pos',
      value: 'institution-foodcourt',
      iconName: 'bulk-orders',
      upDown: false,
      queryPath: {
        pageno: 1
      },
      list: []
    },
  ];
  pathChecking() {
    this.configPanel.forEach((element) => {

      if (element.urlCheck === this.main) {
        element.upDown = true;
        element.active = true;
        if (element.list?.length > 0) {
          element.list.forEach((configList) => {
            if (configList.value === this.subpath.split('?')[0]) {
              configList.active = true;
            }
          });
        }
      } else {
        element.upDown = false;
      }
    });
  }

  subpath;
  main;
  reloading: boolean = false;

  ngOnInit(): void {
    this.siteID = this._dbService.getsiteId();
    this.route.queryParams.subscribe((params) => {
      if (params) {

        let currentUrl = window.location.href;
        let parts = currentUrl.split('/');

        this.main = parts[parts.length - 2];
        this.subpath = parts[parts.length - 1];
      } else {
        let currentUrl = window.location.href;
        let parts = currentUrl.split('/');

        this.main = parts[parts.length - 2];
        this.subpath = parts[parts.length - 1];
      }

    });
    this.pathChecking();
    this.getsiteid(this.siteID);
    // console.log('institution', this.siteID);
  }
  institutionDetails: any = {};
  getsiteid(siteId) {
    this.Foodcourtservice.getsitebyidInstituteVendor(siteId).subscribe(
      (res: any) => {
        // console.log('response from location', res);
        this.dataSource = res.data;
        // this.dataSource.forEach((element) => {
        this.dataSource.ischeck = false;
        let url = this.dataSource.imgUrl;
        let pattern = /^((http|https|ftp):\/\/)/;
        if (pattern.test(url)) {
          this.dataSource.addimageUrl = url;
        } else {
          this.dataSource.addimageUrl = '';
        }
        // console.log('datasource from siteid', this.dataSource);
        // });
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  makeModulesInActive() {
    this.configPanel.forEach((sp1, itemIndex) => {
      sp1.active = false;
      sp1.upDown = false;

      if (sp1.list.length > 0) {
        sp1.list.forEach((sp2) => {
          sp2.active = false;
        })
      }
    })
  }

  onFirstLevelList(panelIndex) {
    let index = 0;

    for (let firstLevelItem of this.configPanel) {
      if (panelIndex === index) {
        if (firstLevelItem.list.length > 0) {
          firstLevelItem.upDown = !firstLevelItem.upDown;
        } else {
          this.makeModulesInActive();

          firstLevelItem.active = true;

          if (firstLevelItem.queryPath) {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`], { queryParams: firstLevelItem.queryPath });
          } else {
            this.router.navigate([
              `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`,
            ]);
          }

        }
        break;
      }
      index++;
    }
  }

  onSecondLevelList(firstLevelItem, secondLevelItem, panelIndex) {
    if (secondLevelItem.list.length > 0) {
      secondLevelItem.upDown = !secondLevelItem.upDown;
    } else {
      this.makeModulesInActive();

      firstLevelItem.upDown = true;
      firstLevelItem.active = true;
      secondLevelItem.active = true;

      if (secondLevelItem.queryPath) {
        this.router.navigate(
          [`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/${secondLevelItem.value}`],
          { queryParams: secondLevelItem.queryPath }
        );
      } else {
        this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/${secondLevelItem.value}`]);
      }
    }
  }

  onIconsSelected(panelIndex) {
    let index = 0;

    for (let firstLevelItem of this.configPanel) {
      if (panelIndex === index) {
        if (firstLevelItem.list.length > 0) {
          firstLevelItem.upDown = !firstLevelItem.upDown;

          this.makeModulesInActive();

          firstLevelItem.upDown = true;
          firstLevelItem.active = true;
          firstLevelItem.list[0].active = true;


          if (firstLevelItem.list[0].queryPath) {
            this.router.navigate(
              [`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/${firstLevelItem.list[0].value}`],
              { queryParams: firstLevelItem.list[0].queryPath }
            );
          } else {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/${firstLevelItem.list[0].value}`]);
          }
        } else {
          this.makeModulesInActive();

          firstLevelItem.active = true;

          if (firstLevelItem.queryPath) {
            this.router.navigate([`/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`], { queryParams: firstLevelItem.queryPath });
          } else {
            this.router.navigate([
              `/erp/erp-isthara/${firstLevelItem.value}/${firstLevelItem.urlCheck}/list-${firstLevelItem.urlCheck}`,
            ]);
          }

        }
        break;
      }
      index++;
    }
  }

  logoclick() {
    // this.router.navigate(['/erp/erp-isthara/enquiry'],{queryParams:{subPath:"allenquiry",main:'Tenant Leads',config:false}});
  }
  goBack() {
    this.router.navigate(['/erp/erp-isthara/food-court/list-sites']);
  }
  infoClick() {
    this.Foodcourtservice.openAddStaffDialog(
      SiteInstituteInfoComponent,
      '301px',
      '550px',
      this.dataSource,
      '97vw'
    );
  }
}
