import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PopupDbService } from './../services/popup-db.service';
import { SigninSignupServicesService } from '../services/signin-signup-services.service';
// import { DbStorageService } from './../../../service/headers/db-storage/db-storage.service';
import { CountdownComponent } from 'ngx-countdown';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'user-flow-signin-signup-flow',
  templateUrl: './signin-signup-flow.component.html',
  styleUrls: [
    './signin-signup-flow.component.scss',
    './mq-signin-signup-flow.component.scss',
  ],
})
export class SigninSignupFlowComponent implements OnInit {
  viewMode = 'signIn';
  errorMessage: boolean = false;
  loader: boolean = false;
  staffpassword = '';
  staffusername = '';
  constructor(
    private apiService: SigninSignupServicesService,
    private router: Router,
    private PopupDbService: PopupDbService
  ) { }

  mobileExp = '^[5-9][0-9]{9}$';
  mailExp =
    '^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$';
  typefUserAuth;
  otpValue: any = null;

  user = {
    countryCode: '91',
    mobile: '',
    email: '',
    value: '',
    roles: ['USER'],
    fullName: '',
    otp: '',
  };

  // keywords
  login = {
    countryCode: '91',
    mobile: '',
    email: '',
    value: '',
  };
  signUp = {
    fullname: '',
    mobile: '',
    email: '',
    countryCode: '91',
    roles: ['USER'],
  };

  verifyotp = {
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  };
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '30px',
      height: '30px',
      background: '#f8fffe',
      border: '1px solid gray',
      padding: '10px',
      'margin-top': '10px',
      'text-align': 'center',
      'font-size': '18px',
      'font-weight': '900',
      outline: 'none',
      color: '#ff1872',
      'font-family': 'nunitoBold',
      'margin-right': 'calc((100% - 276px)/9)',
    },
  };

  counterAction = 'start';
  @ViewChild('countdown', { static: false })
  private countdown: CountdownComponent;
  ngOnInit(): void {
    // console.log(this.PopupDbService.getToken());
    if (this.PopupDbService.getToken() === null) {
    } else {
      this.router.navigate(['/erp/erp-isthara/enquiry'], {
        queryParams: { subPath: 'allenquiry', main: 'Tenant Leads' },
      });
    }
  }

  handleCounter(ev) {
    this.counterAction = ev.action;
  }

  // //otp fields onfocus
  // move(fromnum, tonum, event) {
  //   var charCode = event.which ? event.which : event.keyCode;
  //   // Only Numbers 0-9
  //   if (charCode < 48 || charCode > 57) {
  //     event.preventDefault();
  //     fromnum.style.border = '2px solid red';
  //     fromnum.style.backgroundColor = '#ff1872';
  //     fromnum.style.color = '#fff';
  //     // return false;
  //   }
  //   else {
  //     fromnum.style.border = '1px solid gray';
  //     fromnum.style.backgroundColor = '#fff';
  //     fromnum.style.color = '#ff1872';
  //     var length = fromnum.length;
  //     var maxlength = fromnum.getAttribute(maxlength);
  //     if (length == maxlength) {
  //       tonum.focus();
  //     }
  //   }
  // }
  // back(fromnum, tonum, event) {
  //   var key = event.keyCode || event.charCode;
  //   if (key == 8) {
  //     var length = fromnum.length;
  //     var maxlength = fromnum.getAttribute(maxlength)
  //     if (length == maxlength) {
  //       tonum.focus()
  //     }
  //   }
  // }
  onOtpEnter(otp) {
    this.otpValue = otp;
    // console.log(this.otpValue)
  }

  //api integrations
  signUpdata;
  registerBtn() {
    this.signUpdata = {
      email: this.user.email,
      fullName: this.user.fullName,
      mobile: this.user.mobile,
      roles: this.user.roles,
      countryCode: this.user.countryCode,
    };
    if (
      this.user.fullName === '' ||
      this.user.mobile === '' ||
      this.user.email === '' ||
      this.user.email === this.user.mobile
    ) {
      this.PopupDbService.openSnackBarMessage(
        'Please fill appropriate details',
        'Close'
      );
      // console.log("enter all mandatory fields",signUpdata)
    } else {
      this.apiService.signUp(this.signUpdata).subscribe(
        (signUpdata: any) => {
          if (signUpdata.error) {
            // console.log(signUpdata.message, 'error occurs');
            this.PopupDbService.openSnackBarMessage(signUpdata.message, 'OK');
          } else {
            // console.log('successfully Registered', signUpdata);
            this.PopupDbService.openSnackBarMessage(
              `OTP sent to ` + this.signUp.mobile,
              'close'
            );
            this.viewMode = 'otp';
          }
        },
        (err) => {
          // console.log("gettting error", err)
          this.PopupDbService.openSnackBarMessage(err.error.message, 'Cancel');
          // this.viewMode = 'signIn';
        }
      );
    }
  }
  signIndata;
  expTestSignUp(e) {
    this.errorMessage = false;
    const mExp = new RegExp(this.mobileExp);
    const emailExp = new RegExp(this.mailExp);
    if (mExp.test(e)) {
      this.typefUserAuth = 'mobile';
      this.user.mobile = this.user.value;
      this.signIndata = {
        mobile: this.user.mobile,
        countryCode: this.user.countryCode,
      };
    } else if (emailExp.test(e)) {
      this.typefUserAuth = 'email';
      this.user.email = this.user.value;
      this.signIndata = {
        email: this.user.email,
      };
    } else {
      this.errorMessage = true;
    }
  }

  //login api

  loginBtn() {
    this.loader = true;
    // if (this.user.value == null || this.user.value == '') {
    //   // console.log('please fill all mandatory fileds', this.signIndata);
    //   this.PopupDbService.openSnackBarMessage(
    //     'Please Enter Mobile / Email id',
    //     'Cancel'
    //   );
    // } else {
    let details = {
      userName: this.staffusername,
      password: this.staffpassword,
    };
    this.apiService.stafflogin(details).subscribe(
      (res: any) => {
        this.router.navigate(['/erp/erp-isthara/enquiry'], {
          queryParams: {
            subPath: 'allenquiry',
            main: 'Tenant Leads',
            config: 'main',
          },
        });
        // console.log('stafff', res.data);
        this.PopupDbService.setStaffId(res.data.staffId);
        this.PopupDbService.setUserMobile(res.data.contactDetails.mobile.mobile);
        this.PopupDbService.setToken(res.data.token);
        this.PopupDbService.setUserName(res.data.contactDetails.name);
        this.PopupDbService.setPermissions(res.data.permissions);
      },
      (err: any) => {
        // console.log("not correct",err)
        this.PopupDbService.openSnackBarMessage(
          'Invalid credentials',
          'Cancel'
        );
        this.loader = false;
      }
    );
    // }
  }

  //verification for otp

  verifyBtn() {
    // let finalOtp =
    //   this.verifyotp.otp1.toString() +
    //   this.verifyotp.otp2.toString() +
    //   this.verifyotp.otp3.toString() +
    //   this.verifyotp.otp4.toString() +
    //   this.verifyotp.otp5.toString() +
    //   this.verifyotp.otp6.toString();
    let verifyOtpObj = null;
    if (this.typefUserAuth === 'mobile') {
      verifyOtpObj = {
        countryCode: this.user.countryCode,
        mobile: this.user.mobile,
        otp: Number(this.otpValue),
      };
      this.verifyService(verifyOtpObj);
    } else if (this.typefUserAuth === 'email') {
      verifyOtpObj = {
        email: this.user.email,
        otp: Number(this.otpValue),
      };
      this.verifyService(verifyOtpObj);
    }
  }

  verifyService(verifyOtpObj) {
    this.apiService.verifyNow(verifyOtpObj).subscribe(
      (verifyOtp: any) => {
        if (verifyOtp.error) {
          this.PopupDbService.openSnackBarMessage(verifyOtp.messasge, 'Cancel');
        } else {
          // this._dbStorage.setMobileNumber(verifyOtp.data.mobile);
          // this.PopupDbService.setUserId(verifyOtp.data.userId);
          // this.PopupDbService.setToken(verifyOtp.data.token);
          // console.log('suceesfully Verified', verifyOtp);
        }
        this.PopupDbService.openSnackBarMessage('Succesfully Login', 'Cancel');
        // this._dbStorage.setLoginType('isLoggedTrue');
        this.routeTo();
        history.back();
      },
      (err) => {
        // console.log('something went wrong', err.error);
        this.PopupDbService.openSnackBarMessage(err.error.message, 'Cancel');
      }
    );
  }

  //Resend Otp

  resend() {
    let resendotp = {
      mobile: this.login.mobile,
      countryCode: this.login.countryCode,
    };
    // console.log(resendotp.otp)
    this.countdown.restart();
    this.counterAction = 'start';
    this.apiService.resendOtp(this.signIndata || this.signUpdata).subscribe(
      (resendotp: any) => {
        if (resendotp.error) {
          this.PopupDbService.openSnackBarMessage(resendotp.messasge, 'Cancel');
          // console.log('resend otp meassadf', resendotp);
        } else {
          this.PopupDbService.openSnackBarMessage(
            `${resendotp.data} to ${this.user.value}`,
            'close'
          );
        }
      },
      (err) => {
        this.PopupDbService.openSnackBarMessage(err.error.message, 'Cancel');
        // console.log('error message', err);
      }
    );
  }

  routeTo() {
    this.router.navigate(['/erp']);
  }

  changeViewMode(value) {
    this.viewMode = value;
  }
  signin: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  s;
  hide = true;
  get passwordInput() {
    return this.signin.get('password');
  }
}
