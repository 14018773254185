import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class PopupDbService {
  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  openSnackBarMessage(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5 * 1000,
    });
  }

  setPropertyId(body: any) {
    localStorage.setItem('propertyId', JSON.stringify(body));
  }
  setPropertyIdNull() {
    localStorage.setItem('propertyId', null);
  }

  setInstitutionStatus(body: any) {
    localStorage.setItem('institutionStatus', JSON.stringify(body));
  }
  getInstitutionStatus() {
    const institutionStatus = JSON.parse(
      localStorage.getItem('institutionStatus')
    );
    console.log('Status,', institutionStatus);
    return institutionStatus;
  }

  setInstitutionName(body: any) {
    localStorage.setItem('instName', JSON.stringify(body));
  }

  getInstitutionName() {
    const institutionName = JSON.parse(localStorage.getItem('instName'));
    return institutionName;
  }

  setDayEndSaleClosingReport(body:any){
    localStorage.setItem('closingReportsList', JSON.stringify(body));
  }
  
  getDayEndSaleClosingReport(){
    const closingReportsList = JSON.parse(localStorage.getItem('closingReportsList'));
    return closingReportsList;
  }

  getPropertyId() {
    const propertyId = JSON.parse(localStorage.getItem('propertyId'));
    if (propertyId) {
      return propertyId;
    }
    return null;
  }

  setStaffId(body: any) {
    localStorage.setItem('StaffId', JSON.stringify(body));
  }
  getStaffId() {
    const StaffId = JSON.parse(localStorage.getItem('StaffId'));
    if (StaffId) {
      return StaffId;
    }
    return null;
  }
  //set token
  setToken(body: any) {
    localStorage.setItem('token', JSON.stringify(body));
  }

  // * Get user opted Filter information
  getToken() {
    // const token = "token";
    // return token;
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      return token;
    }
    return null;
  }
  //set userName
  setUserName(body: any) {
    localStorage.setItem('UserName', JSON.stringify(body));
  }

  // * Get user opted Filter information
  getUserName() {
    // const token = "token";
    // return token;
    const UserName = JSON.parse(localStorage.getItem('UserName'));
    if (UserName) {
      return UserName;
    }
    return null;
  }

  setUserMobile(body: any) {
    localStorage.setItem('userMobile', JSON.stringify(body));
  }

  getUserMobile() {
    // const token = "token";
    // return token;
    const UserMobile = JSON.parse(localStorage.getItem('userMobile'));
    if (UserMobile) {
      return UserMobile;
    }
    return null;
  }

  setPermissions(body: any) {
    localStorage.setItem('permissions', JSON.stringify(body));
  }

  // * Get user opted Filter information
  getPermissions() {
    // const token = "token";
    // return token;
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    if (permissions) {
      return permissions;
    }
    return null;
  }
  setInstitutionId(body: any) {
    localStorage.setItem('instID', JSON.stringify(body));
  }

  getInstitutionId() {
    const instID = JSON.parse(localStorage.getItem('instID'));
    if (instID) {
      return instID;
    }
    return null;
  }

  setsiteId(body: any) {
    localStorage.setItem('siteID', JSON.stringify(body));
  }

  getsiteId() {
    const siteID = JSON.parse(localStorage.getItem('siteID'));
    if (siteID) {
      return siteID;
    }
    return null;
  }

  setsitename(body: any) {
    localStorage.setItem('siteName', JSON.stringify(body));
  }

  getsitename() {
    const siteName = JSON.parse(localStorage.getItem('siteName'));
    if (siteName) {
      return siteName;
    }
    return null;
  }

  setstartdate(body: any) {
    localStorage.setItem('startdate', JSON.stringify(body));
  }

  getstartdate() {
    const startdate = JSON.parse(localStorage.getItem('startdate'));
    if (startdate) {
      return startdate;
    } else {
      return null;
    }
  }

  setenddate(body: any) {
    localStorage.setItem('enddate', JSON.stringify(body));
  }

  getenddate() {
    const enddate = JSON.parse(localStorage.getItem('enddate'));
    if (enddate) {
      return enddate;
    } else {
      return null;
    }
  }

  setUserFoodSubscriptionPropertyId(body: any) {
    localStorage.setItem('FoodSubscriptionPropertyId', JSON.stringify(body));
  }

  getUserFoodSubscriptionPropertyId() {
    const FoodSubscriptionPropertyId = JSON.parse(localStorage.getItem('FoodSubscriptionPropertyId'));
    if (FoodSubscriptionPropertyId && FoodSubscriptionPropertyId !== 'null') {
      return FoodSubscriptionPropertyId;
    } else {
      return '';
    }
  }


}
