import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupDbService } from '../../../authentication/services/popup-db.service';
import { SigninSignupServicesService } from '../../../authentication/services/signin-signup-services.service';

@Component({
  selector: 'app-foodcourt-panel',
  templateUrl: './foodcourt-panel.component.html',
  styleUrls: ['./foodcourt-panel.component.scss']
})
export class FoodcourtPanelComponent implements OnInit {

  institutionID='';
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private dbService: PopupDbService,
    private institutionService : SigninSignupServicesService
  ) { }
  rotate: number = 0;

  @Input() expantion: boolean;
  @Input() srcWidth;
  configPanel = [
    {
      name: 'Food Court',
      value: 'food-court',
      iconName: 'foodcourt',
      upDown: false,
      allParams:false,
      list: [
        {
          name: 'Sites',
          value: 'sites',
          active: false,
          queryPath: false,
        },
        {
          name: 'Vendors',
          value: 'vendors',
          active: false,
          queryPath: false,
        },
      ],
    }
  ];
  pathChecking() {
    this.configPanel.forEach((element) => {
      if (element.name === this.main || element.value === this.main) {
        element.upDown = true;
        if (element.list?.length > 0) {
          element.list.forEach((configList) => {
            if (configList.value === this.subpath) {
              configList.active = true;
            }
          });
        }
      }
      else{
        element.upDown = false;
        let currentUrl = window.location.href.split("/");
        // // console.log(currentUrl[currentUrl.length-1]);
        if(element.list.length > 0){
          element.list.forEach(subele => {
            if(subele.value === currentUrl[currentUrl.length-1]){
              element.upDown = true;
              subele.active = true;
            }
          });
        }
      }
    });
      
  }

  subpath;
  main;

  ngOnInit(): void {
    // console.log('srcWidth', this.srcWidth);
    // console.log('expansion',this.expantion);
    this.route.queryParams.subscribe((params) => {
      if(params.subPath || params.main ){
        this.subpath = params.subPath;
        this.main = params.main
        this.pathChecking();
      }
      else{
        let currentUrl = window.location.href;
        let parts = currentUrl.split("/");
        let position = parts.indexOf('erp');
        parts = parts.slice(position);
        this.main = parts[2];
        this.subpath = parts[3];
        this.pathChecking();
      }
    })
    this.getInstitutionDetails(this.dbService.getInstitutionId());
    // console.log("institution",this.institutionID);
  }
  institutionDetails:any = {};
  getInstitutionDetails(id){
    this.institutionService.getInstitute(id).subscribe(
      (res:any)=>{
        // console.log(res.data);
        this.institutionDetails = res.data;
      },
      (err) =>{
        // console.log(err);
      }
    )
  }
  listDisplay(item, index) {
    this.rotate = index;
  }
  showHide(flag, index) {
    flag = !flag;
    this.configPanel.forEach((item,i) => {
      if(i === index){
        item.upDown = flag;
        if(item.allParams){
          this.router.navigate([`/erp/erp-isthara/${item.value}`],{queryParams:{subPath:item.list[1].value,main:item.name,config:'configuration'}});
          item.list[1].active = true;
        }
        else{
          if(item.list?.length>0){
              this.router.navigate([`/erp/erp-isthara/${item.value}/${item.list[0].value}`]);
              item.list[0].active = true;
          }
          else{
            this.router.navigate([`/erp/erp-isthara/${item.value}`])
          }
        }
      }
      else{
        item.upDown = false;
      }
    });
  }

  routePath(item, data, index) {
    // console.log(item, data);
    item.list.forEach((element, lIndex) => {
      if (index === lIndex) {
        element.active = true;
      } else {
        element.active = false;
      }
    });
    if (data.queryPath) {
      this.router.navigate(['/erp/erp-isthara/' + item.value], {
        queryParams: { subPath: data.value, main: item.name, config: 'configuration'},
      });
      // console.log('item,subpath,main', item.value, data.value, item.name);
    } else {
      this.router.navigate([`/erp/erp-isthara/${item.value}/${data.value}/list-${data.value}`]);
    }
  }

  logoclick() {
    // this.router.navigate(['/erp/erp-isthara/enquiry'],{queryParams:{subPath:"allenquiry",main:'Tenant Leads',config:false}});
  }
  goBack(){
    this.router.navigate(['/erp/erp-isthara/enquiry'],{queryParams:{subPath:"allenquiry",main:'Tenant Leads',config:'main'}});
  }
}
