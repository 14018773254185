import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SigninSignupFlowComponent } from './components/erp-landing/authentication/signin-signup-flow/signin-signup-flow.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { ConstMaterial } from './components/constant-material.module';
import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [AppComponent, SigninSignupFlowComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ConstMaterial,
    HttpClientModule,
    CommonModule,
    CountdownModule,
    NgOtpInputModule
  ],
  providers:[
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
