import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from './../../../../../environments/environment';
import { BlobServiceClient } from '@azure/storage-blob';

@Injectable({
  providedIn: 'root'
})
export class StudentlivingImageUploadService {

  constructor() { }
  imageObject;


  async uploadFile(file: File, folderName: string) {
    const sasToken = environment.sasToken;
    const accountName = environment.accountName;
    const containerName = environment.containerName;

    const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobName = file.name;
    const blockBlobClient = containerClient.getBlockBlobClient(folderName + "/" + blobName);
  
    await blockBlobClient.uploadData(file, {
      blobHTTPHeaders: { blobContentType: file.type },
    });

    return blockBlobClient;
  }

  async uploadstudentlivingImages(element) {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.image.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.image.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-07-10',
      params: {
        Bucket: bucketName,
      },
    });

    // const element = e.srcElement.files[0];

    let imgPromise = new Promise((res, rej) => {
      if (element) {
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          s3.upload(
            {
              Key:
                'student-living/' +
                Math.floor(100000 + Math.random() * 900000) +
                'c' +
                new Date().getTime() +
                element.name,
              Bucket: bucketName,
              Body: element,
              ACL: 'public-read',
            },
            function (err: any, data: any) {
              if (err) {
                rej(err);
              } else {
                res(data);
              }
            }
          );
        };
      }
    });

    return imgPromise
      .then((data) => {
        this.imageObject = data;
        console.log('awsResult', data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }


  async deleteProfileImage(element) {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.image.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.image.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-07-10',
      params: {
        Bucket: bucketName,
      },
    });

    // const element = e.srcElement.files[0];

    let imgPromise = new Promise((res, rej) => {
      if (element) {
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
          const params=  {
            Key:element,
            Bucket: bucketName,
          }
          s3.deleteObject(params,
            function (err: any, data: any) {
              if (err) {
                console.log('There was an error deleting your file: ', err.message);
                return;
              }
              console.log('Successfully deleted file.');
            }
          );
        // };
      }
    });

    // return imgPromise
    //   .then((data) => {
    //     this.imageObject = data;
    //     console.log('awsResult', data);
    //     return true;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     return err;
    //   });
  }
}
